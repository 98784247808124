import {AiOutlinePauseCircle, AiOutlinePrinter} from 'react-icons/ai';
import {GrInProgress} from 'react-icons/gr';
import {FcCallback, FcMissedCall, FcShipped} from 'react-icons/fc';
import {MdSmsFailed} from 'react-icons/md';
import {BiHappyHeartEyes} from 'react-icons/bi';
import {PiKeyReturnFill} from 'react-icons/pi';
import {TiInputChecked} from 'react-icons/ti';

const CONFIG = {
  isoFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  apiEndpoint: window.location.host === 'app.baohiemexpress.com' ? 'https://api.baohiemexpress.com' : 'https://backup-api.baohiemexpress.com',
  tinyMceKey: 'rp7ie29k5lmy83vv1b8x18mw04urimug739u21zh59uuxucc',
  allowBulkChangeStatuses: [
    'NEW',
    'IN_PROGRESS',
    'NO_PICKUP',
    'CALL_LATER',
    'SALE_FAIL',
    'SALE_SUCCESS',
    'MANAGER_CONFIRMED',
  ],
  taskStatuses: [
    {value: 'NEW', label: 'Chưa xử lý', icon: (<AiOutlinePauseCircle color={'blue'} size={20}/>)},
    {value: 'IN_PROGRESS', label: 'Đang xử lý', icon: (<GrInProgress size={20}/>)},
    {value: 'NO_PICKUP', label: 'Số máy bận', icon: (<FcMissedCall size={20}/>)},
    {value: 'CALL_LATER', label: 'Gọi lại sau', icon: (<FcCallback size={20}/>)},
    {value: 'SALE_FAIL', label: 'Khách từ chối', icon: (<MdSmsFailed color={'#ff5959'} size={20}/>)},
    {value: 'SALE_SUCCESS', label: 'Đã chốt đơn', icon: (<BiHappyHeartEyes color={'blue'} size={20}/>)},
    {value: 'MANAGER_CONFIRMED', label: 'Đã phê duyệt', icon: (<TiInputChecked color={'green'} size={23}/>)},
    {value: 'PRINTED', label: 'Đã in', icon: (<AiOutlinePrinter color={'orange'} size={20}/>)},
    {value: 'SHIPPED', label: 'Đã ship', icon: (<FcShipped size={20}/>)},
    {value: 'RETURNED', label: 'Đơn hoàn trả', icon: (<PiKeyReturnFill size={20} color={'#ff5959'}/>)},
  ],
  roles: [
    {value: 5, label: 'Người in'},
    {value: 4, label: 'Nhân viên'},
    {value: 3, label: 'Quản lý'},
  ],
  allowStatusByRoles: {
    'manager': [
      'NEW',
      'IN_PROGRESS',
      'NO_PICKUP',
      'CALL_LATER',
      'SALE_FAIL',
      'SALE_SUCCESS',
      'MANAGER_CONFIRMED',
      'PRINTED',
      'SHIPPED',
      'RETURNED',
    ],
    'staff': [
      'NEW',
      'IN_PROGRESS',
      'NO_PICKUP',
      'CALL_LATER',
      'SALE_FAIL',
      'SALE_SUCCESS',
    ],
    'printer': [
      'SALE_SUCCESS',
      'MANAGER_CONFIRMED',
      'PRINTED',
      'SHIPPED',
      'RETURNED',
    ],
  },
  allOption: {value: 'all', label: 'Tất cả'},
  apiDateFormat: 'YYYY-MM-DD',
  humanDateFormat: 'DD/MM/YYYY',
  exelDateFormat: 'DD-MM-YYYY',
  apiDatetimeFormat: 'YYYY-MM-DD HH:mm:ss',
};

export default CONFIG;
