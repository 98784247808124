import styles from './_page.module.scss';
import {
  Button, DatePicker,
  Dropdown,
  Form,
  Input,
  message, Pagination,
  Popconfirm,
  Select,
  Space,
} from 'antd';
import {Link} from 'react-router-dom';
import {useEffect, useState, Fragment} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {BiSolidDownArrow} from 'react-icons/bi';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {formatDate, mappingRoleToLabel} from '../../helper';

const endpoint = _pageConfig.endpoint;
export default function UsersPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [actions, data] = useStrapiRest(endpoint);
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      await actions.getItems({
        limit: pageSize,
        populate: ['role']
      });
    })();
  }, []);

  return (
      <AuthLayout pageTitle={'Danh sách users'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">Danh sách users</div>
                  <Link to={`/${endpoint}/edit`}>
                    <Button type={'primary'}>Thêm mới</Button>
                  </Link>
                </div>

                {(!data.list || !data.list.length) && (
                    <div className="noContent">
                      Không tìm thấy dữ liệu.
                    </div>
                )}

                {!!data.list && !!data.list.length && (
                    <Fragment>
                      <table className={'tableContent'}>
                        <thead>
                        <tr>
                          <th className={'no'}>No</th>
                          <th>Tên</th>
                          <th>Mã nhân viên</th>
                          <th>Email</th>
                          <th>Vai trò</th>
                          <th>Ngày tạo</th>
                          <th className={'actions'}>Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.list?.map((item, index) => {
                          return (
                              <tr key={item.id}>
                                <td className={'no'}>
                                  {index + 1 + pageSize * (currentPage - 1)}
                                </td>
                                <td>
                                  {item.nickName}
                                </td>
                                <td>
                                  {item.code}
                                </td>
                                <td>
                                  {item.email}
                                </td>
                                <td>
                                  {item?.role?.name}
                                </td>
                                <td>
                                  {formatDate(item.createdAt)}
                                </td>
                                <td className={'actions'}>
                                  <Space>
                                    <Link to={`/${endpoint}/edit/${item.id}`}>
                                      <Button>Sửa</Button>
                                    </Link>
                                    <Popconfirm
                                        title="Vui lòng xác nhận!"
                                        description="Bạn chắc chắn muốn thực hiện thao tác này?"
                                        onConfirm={async () => {
                                          try {
                                            await actions.deleteItem(item.id);
                                            message.success('Xóa thành công!');
                                            await actions.getItems({limit: 1000});
                                          } catch (e) {
                                            message.warning('Có lỗi xảy ra!');
                                          }
                                        }}
                                    >
                                      <Button>Xóa</Button>
                                    </Popconfirm>
                                  </Space>
                                </td>
                              </tr>
                          )
                        })}
                        </tbody>
                      </table>
                      <div className="tablePagination">
                        <Pagination onChange={async (page) => {
                          setCurrentPage(page);
                          await actions.getItems({
                            page,
                            limit: pageSize
                          })
                        }} current={currentPage} pageSize={pageSize} total={data?.listPagination?.total} />
                      </div>
                    </Fragment>
                )}

              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
