import styles from './_page.module.scss';
import {Button, Form, Input, message, Select} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import CONFIG from '../../config';
import useCurrentUser from '../../hooks/useCurrentUser';
import {getAxios} from '../../services/api';

const endpoint = _pageConfig.endpoint;
export default function UserUpdatePage() {
  const [actions, data] = useStrapiRest(endpoint);
  const params = useParams();
  const [id, setId] = useState();
  const history = useHistory();
  const [form] = Form.useForm();
  const currentUser = useCurrentUser();

  const [uploadedId, setUploadedId] = useState(null);
  const hiddenFileRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload the file using Axios
      const formData = new FormData();
      formData.append('files', file);

      getAxios().post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log('File uploaded successfully:', response.data);
        // Here you can handle the response as needed, like storing the URL of the uploaded image
        setUploadedId(response.data[0].id);
        message.success('Upload ảnh thành công!');
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
    }
  };

  useEffect(() => {
    (async () => {
      setId(params.id);
      await actions.getItem(params.id, {
        populate: ['role', 'avatar'],
      });
    })();
  }, [params.id]);

  function onFormLayoutChange() {

  };

  useEffect(() => {
    if (data.detail) {
      form.setFieldsValue({
        ...data.detail,
        roleId: data.detail?.role?.id || null,
      });
    } else {
      form.setFieldsValue({
        roleId: 4,
      });
    }
  }, [JSON.stringify(data?.detail)]);

  async function onFinish(values) {
    try {
      if (uploadedId) {
        values.avatar = uploadedId;
      }

      if (!id) {
        await actions.customPost('', {
          ...values,
          role: values.roleId,
          confirmed: true,
        });
        message.success('Tạo mới thành công!');
        history.push(`/${endpoint}`);
      } else {
        await actions.customUpdate(id, {
          ...values,
          role: values.roleId,
        });
        message.success('Cập nhật thành công!');
        history.push(`/${endpoint}`);
      }
    } catch (e) {
      console.error(e);
      message.warning('Có lỗi xảy ra!');
    }
  }

  return (
      <AuthLayout pageTitle={id ? 'Cập nhật người dùng' : 'Thêm mới người dùng'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <Form
                  form={form}
                  onFinish={onFinish}
                  colon={false}
                  layout="vertical"
                  onValuesChange={onFormLayoutChange}
                  style={{
                    width: '100%',
                  }}
                  className={'oneColumns'}
              >
                <Form.Item
                    name={'username'}
                    label={'Tên đăng nhập'}
                    rules={[{required: true}]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name={'nickName'}
                    label={'Tên nhân viên'}
                    rules={[{required: true}]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name={'email'}
                    label={'Email'}
                    rules={[{required: true}]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name={'code'}
                    label={'Mã nhân viên'}
                    rules={[{required: true}]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name={'password'}
                    label={'Mật khẩu mới'}
                    rules={[
                      {required: !id},
                      {min: 6},
                      {max: 16},
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name={'roleId'}
                    label={'Vai trò'}
                    rules={[{required: true}]}
                >
                  <Select disabled={String(currentUser?.id) === String(id)} options={CONFIG.roles}/>
                </Form.Item>
                <div style={{ marginBottom: '20px' }}>
                  <Button onClick={() => hiddenFileRef.current.click()}>Chọn file ảnh</Button>
                  <input
                      accept={'image/*'}
                      style={{ width: '1px', height: '1px', opacity: 0.1 }}
                      type="file"
                      ref={hiddenFileRef}
                      onChange={handleFileSelect}
                  />
                </div>

                {!imagePreview && data?.detail?.avatar && (
                    <div style={{ marginBottom: '20px' }}>
                      <img src={CONFIG.apiEndpoint + data?.detail?.avatar?.url} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    </div>
                )}

                {imagePreview && (
                    <div style={{ marginBottom: '20px' }}>
                      <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    </div>
                )}
                <Form.Item className={'submitRow'}>
                  <Button block type="primary" htmlType="submit">
                    {id ? 'Cập nhật' : 'Tạo mới'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
