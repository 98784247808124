import {getAxios} from '../services/api';
import {useState} from 'react';
import qs from 'qs';

const useStrapiRest = (endpoint) => {
  const [data, setData] = useState([]);
  const [apiState, setApiState] = useState({
    isError: false,
    isLoading: false,
  });

  const actions = {
    getItems: async (params = {}) => {
      // Clean params
      const clone = {
        ...params
      }

      const query = qs.stringify(clone);

      setApiState({
        ...apiState,
        isError: false,
        isLoading: true,
      });

      let response;
      try {
        const result = await getAxios().get(`/api/${endpoint}?${query}`);
        response = {
          ...data,
          list: result.data.data || result.data,
          listPagination: result.data.meta || result.meta
        }
        setData(response);
      } catch (error) {
        setApiState({
          ...apiState,
          isError: true,
          isLoading: true,
        });
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });

      return response;
    },
    getItem: async (id, params = {}) => {
      // Clean params
      const clone = {
        ...params
      }

      const query = qs.stringify(clone);

      setApiState({
        ...apiState,
        isError: false,
        isLoading: true,
      });

      let result;

      try {
        result = await getAxios().get(`/api/${endpoint}/${id}?${query}`);

        setData({
          ...data,
          detail: result.data.data || result.data
        });
      } catch (error) {
        console.error(error);
        setApiState({
          ...apiState,
          isError: true,
        });
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });

      return result;
    },
    customGet: async (path, params = {}, options = {}) => {
      // Clean params
      const clone = {
        ...params
      }

      const query = qs.stringify(clone);

      setApiState({
        ...apiState,
        isError: false,
        isLoading: true,
      });

      let result;
      try {
        result = await getAxios().get(`/api/${endpoint}/${path}?${query}`, options);
      } catch (error) {
        console.error(error);
        setApiState({
          ...apiState,
          isError: true,
        });
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });

      return result;
    },
    deleteItem: async (id) => {
      setApiState({
        ...apiState,
        isLoading: true,
      });

      try {
        await getAxios().delete(`/api/${endpoint}/${id}`);
      } catch (error) {
        console.error(error);
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });
    },
    manualSetData: async (newData) => {
      setData(newData)
    },
    createItem: async (values) => {
      setApiState({
        ...apiState,
        isLoading: true,
      });

      let result;
      try {
        result = await getAxios().post(`/api/${endpoint}`, {
          // ...values,
          data: values,
        });
      } catch (error) {
        console.error(error);
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });

      return result.data.data || result.data;
    },
    updateItem: async (id, values) => {
      setApiState({
        ...apiState,
        isLoading: true,
      });

      let result;
      try {
        result = await getAxios().put(`/api/${endpoint}/${id}`, {
          // ...values,
          data: values,
        });
      } catch (error) {
        console.error(error);
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });
      return result.data.data || result.data;
    },
    customUpdate: async (path, values) => {
      setApiState({
        ...apiState,
        isLoading: true,
      });

      let result;
      try {
        result = await getAxios().put(`/api/${endpoint}/${path}`, {
          ...values,
        });
      } catch (error) {
        console.error(error);
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });
      return result.data.data || result.data;
    },
    customPost: async (path, values) => {
      setApiState({
        ...apiState,
        isLoading: true,
      });

      let result;
      try {
        result = await getAxios().post(`/api/${endpoint}/${path}`, {
          ...values,
        });
      } catch (error) {
        console.error(error);
      }

      setApiState({
        ...apiState,
        isLoading: false,
      });
      return result.data.data || result.data;
    },
  };

  return [actions, data, apiState];
};

export default useStrapiRest;
