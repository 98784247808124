
import styles from './_page.module.scss';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import moment from 'moment';
import _ from 'lodash';
import {numberWithCommas} from '../../helper';

const JsBarcode = require('jsbarcode');
const endpoint = _pageConfig.endpoint;
export default function PrintHistoryPrintPage() {
  const [actions, data] = useStrapiRest(endpoint);
  const params = useParams();
  const [id, setId] = useState();
  useEffect(() => {
    (async () => {
      setId(params.id);
      await actions.getItem(params.id, {
        sort: ['contractNo'],
        populate: ['user', 'tasks.user'],
      });
    })();
  }, [params.id]);

  useEffect(() => {
    if (data && data.detail) {
      setTimeout(() => {
        // JsBarcode(".barcode").init();
        // data.detail.tasks.forEach(task => {
        //   JsBarcode(`#barcode-${task.contractNo}`);
        // });
        JsBarcode(".barcode", "CODE39 Barcode", {
          format: "CODE39"
        }).init();

        setTimeout(() => {
          window.print();
        }, 500);
      }, 200);
    }
  }, [data?.detail]);

  return (
      <div className={'pageWrapper'}>
        <div className={'pageContent'}>
          <div className={styles.moduleWrapper}>

            <div className="printItems">
              {_.orderBy(data?.detail?.tasks, ['contractNo']).map((item, index) => {
                return (
                    <div className={'printItem'}>
                      <div className="top">
                        <div className="line">
                          <strong>{item.customerName} ({item?.user?.code || 'N/A'})</strong>
                        </div>
                        <div className="line">{item.customerAddress}</div>
                        <div className="line barAndPhone">
                          <div className="codeWrapper">
                            <svg className="barcode"
                                 jsbarcode-value={item.contractNo}
                                 jsbarcode-textmargin="0"
                                 jsbarcode-fontoptions="bold"
                                 jsbarcode-fontsize="0"
                            >
                            </svg>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '-7px'}}>
                              {item.contractNo}
                            </div>
                          </div>
                          <div className="phone">
                            <div className="line">
                              <strong>0{item.customerPhoneNumber}</strong>
                            </div>
                            <div className="line">{item?.user?.code || 'N/A'} {numberWithCommas(item.contractPrice) || 'Thiếu giá'}</div>
                          </div>
                        </div>
                      </div>
                      <div className="bottom" style={{marginTop: '50px'}}>
                        <div className="line">{item.plateNumber}{item?.user?.code || 'N/A'}</div>
                        <div className="line">{item?.contractNo}</div>
                        <div className="line">ED {item.contractTo ? moment(item.contractTo).format('DD/MM') : ''}</div>
                      </div>
                      <div className="bottom">
                        <div className="line">{item.printNote || 'N/A'}</div>
                      </div>

                    </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
  );
}
