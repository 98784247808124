import {Button, Form, Input, Modal, Select} from 'antd';
import {useEffect, useState} from 'react';
import slugify from 'slugify';
import useStrapiRest from '../hooks/useStrapiRest';
import {useWatch} from 'antd/es/form/Form';
import axios from 'axios';

// Function to remove accents and normalize the string
const normalizeString = (str) => {
  if (str) {
    return slugify(str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), {
      lower: true,
      strict: true,
    });
  }

  return '';
};


export const AddressModal = ({
                               isShowAddressModal,
                               setIsShowAddressModal,
                               customerAddress,
                               customerJsonAddress,
                               onUseAddress,
    onCloseModal
}) => {
  const [addressForm] = Form.useForm();
  const [address, setAddress] = useState(null);
  const [actions] = useStrapiRest('address');
  const [isStopResetField, setIsStopResetField] = useState(true);

  useEffect(() => {
    (async () => {
      const res = await actions.getItems();
      setAddress(res.list);
    })();
  }, [])

  const selectedProvince = Form.useWatch('province', addressForm);
  const selectedDistrict = Form.useWatch('district', addressForm);
  const selectedWard = Form.useWatch('ward', addressForm);
  const selectedSubward = Form.useWatch('subward', addressForm);
  const selectedDetail = Form.useWatch('detail', addressForm);
  const [subWardOptions, setSubWardOptions] = useState([]);
  const [fullAddress, setFullAddress] = useState(null);

  useEffect(() => {
    const components = [];
    if (selectedProvince) {
      components.push(address.provinces.find(item => item.PROVINCE_ID === selectedProvince)?.PROVINCE_NAME);
    }

    if (selectedDistrict) {
      components.push(address.districts.find(item => item.DISTRICT_ID === selectedDistrict)?.DISTRICT_NAME);
    }

    if (selectedWard) {
      components.push(address.wards.find(item => item.LOCATION_CODE === selectedWard)?.WARDS_NAME);
    }

    if (selectedSubward) {
      components.push(subWardOptions.find(item => item.value === selectedSubward)?.label);
    }

    if (selectedDetail) {
      components.push(selectedDetail);
    }

    setFullAddress(components.reverse().join(', '));
  }, [
      selectedProvince,
      selectedDistrict,
      selectedWard,
      selectedSubward,
      selectedDetail,
      subWardOptions,
  ]);

  useEffect(() => {
    if (isStopResetField) {
      return;
    }

    addressForm.setFieldsValue({
      district: null,
      ward: null,
      subward: null,
    });
  }, [selectedProvince])

  useEffect(() => {
    if (isStopResetField) {
      return;
    }

    addressForm.setFieldsValue({
      ward: null,
      subward: null,
    });
  }, [selectedDistrict])

  useEffect(() => {
    (async () => {
      if (!selectedWard) {
        return;
      }

      const res = await axios.get(`https://location.viettelpost.vn/location/v1.0/autocomplete?system=VTP&ctx=SUBWARD&ctx=${selectedWard}`)
      setSubWardOptions(res.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          type: item.type,
        }
      }));

      if (!isStopResetField) {
        addressForm.setFieldsValue({
          subward: null,
        });
      }
    })();
  }, [selectedWard])

  useEffect(() => {
    (async () => {
      if (!address || !customerAddress) {
        return;
      }

      // If not existed json, process to suggest
      if (!customerJsonAddress) {
        // Search first
        const searchRes = await axios.get(`https://location.viettelpost.vn/location/v1.0/autocomplete?system=VTP&q=${decodeURIComponent(customerAddress)}`);
        const suggestAddress = searchRes?.data[0]?.name;
        if (suggestAddress) {
          const resSuggest = await axios.post('https://location.viettelpost.vn/location/v1.0/address', {
            address: suggestAddress,
          });

          const province = resSuggest.data.components.find((item) => item.type === 'PROVINCE');
          const district = resSuggest.data.components.find((item) => item.type === 'DISTRICT');
          const ward = resSuggest.data.components.find((item) => item.type === 'WARD');

          const subWard = resSuggest.data.components.find((item) => ['STREET'].includes(item.type));
          await addressForm.setFieldsValue({
            province: address.provinces.find(item => item.VALUE === province?.code)?.PROVINCE_ID,
            district: address.districts.find(item => item.VALUE === district?.code)?.DISTRICT_ID,
            ward: address.wards.find(item => item.LOCATION_CODE === ward?.id)?.LOCATION_CODE,
            subward: subWard?.id,
            detail: suggestAddress.split(' - ').length ? suggestAddress.split(' - ')[0] : null
          });
        }

        setTimeout(() => {
          setIsStopResetField(false);
        }, 1000);
      } else {
        // If existed json, set to form
        await addressForm.setFieldsValue({
          province: customerJsonAddress.province,
          district: customerJsonAddress.district,
          ward: customerJsonAddress.ward,
          subward: customerJsonAddress.subward,
          detail: customerJsonAddress.detail,
        });

        setTimeout(() => {
          setIsStopResetField(false);
        }, 1000);
      }
    })();
  }, [address, customerAddress, customerJsonAddress]);

  return (
      address && (
          <Modal
              centered
              width={'500px'}
              title={'KIỂM TRA ĐỊA CHỈ KHÁCH HÀNG'}
              // closeIcon={null}
              open={isShowAddressModal}
              onCancel={() => {
                setIsShowAddressModal(false)
              }}
              footer={null}
          >
            {address && (
                <Form
                    name={'addressForm'}
                    form={addressForm}
                    onFinish={(values) => {
                      console.log('DEBUG values', values);
                    }}
                    colon={false}
                    layout="vertical"
                    style={{
                      width: '100%',
                    }}
                    className={'fiveColumns'}
                    onValuesChange={(values) => {
                      console.log('DEBUG onValuesChange', values);
                    }}
                >

                  {/*selectedProvince = {selectedProvince}*/}
                  {/*<br/>*/}
                  {/*selectedDistrict = {selectedDistrict}*/}
                  {/*<br/>*/}
                  {/*selectedWard = {selectedWard}*/}
                  {/*<br/>*/}
                  {/*selectedSubward = {selectedSubward}*/}
                  {/*<br/>*/}
                  {/*selectedDetail = {selectedDetail}*/}

                  <Form.Item
                      name={'province'}
                      label={'Tỉnh/Thành phố'}
                      style={{
                        width: '100%',
                      }}
                  >
                    <Select
                        allowClear={true}
                        showSearch={true}
                        style={{width: '100%'}}
                        placeholder={'Chọn tỉnh/thành phố'}
                        options={address.provinces.map((item) => {
                          return {
                            value: item.PROVINCE_ID,
                            label: item.PROVINCE_NAME,
                          }
                        })}
                        filterOption={(input, option) => {
                          const searchText = normalizeString(input.replace(/ /g,""));
                          const provinceSlug = normalizeString(option.label.replace(/ /g,""));

                          return provinceSlug.includes(searchText);
                        }}
                    />
                  </Form.Item>

                  <Form.Item
                      name={'district'}
                      label={'Quận/Huyện'}
                      style={{
                        width: '100%',
                      }}
                  >
                    <Select
                        allowClear={true}
                        showSearch={true}
                        style={{width: '100%'}}
                        placeholder={'Chọn quận/huyện'}
                        options={address.districts
                        .filter((item) => {
                          return item.PROVINCE_ID === selectedProvince;
                        })
                        .map((item) => {
                              return {
                                value: item.DISTRICT_ID,
                                label: item.DISTRICT_NAME,
                              }
                            }
                        )}
                        filterOption={(input, option) => {
                          const searchText = normalizeString(input.replace(/ /g,""));
                          const provinceSlug = normalizeString(option.label.replace(/ /g,""));

                          return provinceSlug.includes(searchText);
                        }}
                    />
                  </Form.Item>

                  <Form.Item
                      name={'ward'}
                      label={'Xã/Phường'}
                      style={{
                        width: '100%',
                      }}
                  >
                    <Select
                        allowClear={true}
                        showSearch={true}
                        style={{width: '100%'}}
                        placeholder={'Chọn xã/phường'}
                        options={address.wards
                        .filter((item) => {
                          return item.DISTRICT_ID === selectedDistrict;
                        })
                        .map((item) => {
                              return {
                                value: item.LOCATION_CODE,
                                label: item.WARDS_NAME,
                              }
                            }
                        )}
                        filterOption={(input, option) => {
                          const searchText = normalizeString(input?.replace(/ /g, "") || input);
                          const provinceSlug = normalizeString(option.label?.replace(/ /g, "") || option.label);

                          return provinceSlug.includes(searchText);
                        }}
                    />
                  </Form.Item>

                  <Form.Item
                      name={'subward'}
                      label={'Đường/xóm/tòa nhà'}
                      style={{
                        width: '100%',
                      }}
                  >
                    <Select
                        allowClear={true}
                        showSearch={true}
                        style={{width: '100%'}}
                        placeholder={'Chọn đường/xóm/tòa nhà'}
                        options={subWardOptions}
                        filterOption={(input, option) => {
                          const searchText = normalizeString(input?.replace(/ /g, "") || input);
                          const provinceSlug = normalizeString(option.label?.replace(/ /g, "") || option.label);

                          return provinceSlug.includes(searchText);
                        }}
                    />

                  </Form.Item>

                  <Form.Item
                      name={'detail'}
                      label={'Số nhà/phòng cụ thể'}
                      style={{
                        width: '100%',
                      }}
                  >
                    <Input />
                  </Form.Item>
                </Form>
            )}


            {customerAddress && (
                <div style={{marginBottom: '24px'}}>Địa chỉ hiện tại: <br/>{customerAddress}</div>
            )}

            {fullAddress && (
                <div style={{marginBottom: '24px'}}>Địa chỉ chuẩn hóa: <br/> <strong>{fullAddress}</strong></div>
            )}

            <Button
                style={{
                  width: '100%',
                }}
                type={'primary'}
                onClick={() => {
                  onUseAddress({
                    customerAddress: fullAddress,
                    customerJsonAddress: {
                      province: selectedProvince,
                      district: selectedDistrict,
                      ward: selectedWard,
                      subward: selectedSubward,
                      detail: selectedDetail,
                    }
                  });
                  onCloseModal();
                }}
            >Sử dụng địa chỉ chuẩn hóa</Button>
          </Modal>
      )
  )
}
