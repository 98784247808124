import readXlsxFile from 'read-excel-file';

import styles from './_page.module.scss';
import {Button, Dropdown, Form, Input, message, Modal, Select, Space} from 'antd';
import {useHistory} from 'react-router-dom';
import {Fragment, useEffect, useRef, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {exportTasksForPrint, exportTasksForShip, getCurrentUser, removeLeadingZero} from '../../helper';
import {AiOutlineCheckCircle, AiOutlineCloudDownload, AiOutlineCloudUpload, AiOutlinePrinter} from 'react-icons/ai';
import CONFIG from '../../config';
import moment from 'moment';
import useCurrentRole from '../../hooks/useCurrentRole';
import useCurrentUser from '../../hooks/useCurrentUser';
import {TaskModal} from '../../components/TaskModal';
import {TableRecords} from '../../components/TableRecords';
import {BiSolidUserVoice} from 'react-icons/bi';
import {FiPackage} from 'react-icons/fi';
import {BsArrowReturnLeft} from 'react-icons/bs';
import {FaBorderAll} from 'react-icons/fa';

const sampleImportUrl = require('../../assets/SAMPLE_IMPORT.xlsx');

const endpoint = _pageConfig.endpoint;
export default function TasksPage() {
  const history = useHistory();
  const [customApiActions] = useStrapiRest('custom-api');
  const [userActions, userData] = useStrapiRest('users');
  const [printHistoryActions] = useStrapiRest('print-histories');
  const [shipHistoryActions] = useStrapiRest('ship-histories');
  const currentRole = useCurrentRole();
  const fileRef = useRef(null);
  const currentUser = useCurrentUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [isImported, setIsImported] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [importResults, setImportResults] = useState(null);
  const [savedSearch, setSavedSearch] = useState({
    populate: {
      user: {
        fields: ['id', 'code', 'nickName']
      }
    },
    sort: ['updatedAt:desc'],
    pagination: {
      pageSize,
      page: currentPage,
    },
  });
  const [isShowModalInputShip, setIsShowModalInputShip] = useState(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isOpenAssignModal, setIsOpenAssignModal] = useState(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [uploadItems, setUploadItems] = useState([]);
  const [shipItems, setShipItems] = useState(null);
  const [actions, data] = useStrapiRest(endpoint);
  const [form] = Form.useForm();
  const [uploadForm] = Form.useForm();
  const [assignForm] = Form.useForm();
  const status = Form.useWatch('taskStatus', {form});

  useEffect(() => {
    (async () => {
      await userActions.getItems({
        populate: {
          role: {
            fields: ['id', 'type'],
          }
        },
        fields: [
          'id',
          'nickName',
          'code'
        ],
        pagination: {
          limit: 1000
        }
      });

      const currentUser = getCurrentUser();
      if (currentUser.role.type === 'manager' || currentUser.role.type === 'printer') {
        await actions.getItems({
          ...savedSearch,
        });
      } else {
        form.setFieldValue('userId', currentUser.id);
        setSavedSearch({
          ...savedSearch,
          filters: {
            user: {
              id: {
                $eq: currentUser.id
              }
            }
          }
        });
        await actions.getItems({
          ...savedSearch,
          filters: {
            user: {
              id: {
                $eq: currentUser.id
              }
            }
          }
        });
      }
    })();
  }, []);

  async function onFinishSearch(values) {
    let andConditions = [];

    ['plateNumber', 'contractNo', 'shippingNo', 'customerPhoneNumber', 'note'].forEach(key => {
      if (values[key]) {
        andConditions.push({
          [key]: {
            $contains: values[key],
          },
        });
      }
    });

    let taskStatus = values.taskStatus;
    if (taskStatus) {
      if (taskStatus.length === 1 && taskStatus[0] === 'all') {
        taskStatus = CONFIG.allowStatusByRoles[currentRole];
      }

      andConditions.push({
        'taskStatus': {
          $in: taskStatus,
        },
      });
    }

    const currentUser = JSON.parse(localStorage.getItem('user'));
    console.log('DEBUG currentUser', currentUser);
    if (currentUser.role.type !== 'manager' && currentUser.role.type !== 'printer') {
      andConditions.push({
        user: {
          id: {
            $eq: currentUser.id,
          },
        },
      });
    } else {
      if (values.userId) {
        andConditions.push({
          user: {
            id: {
              $eq: values.userId,
            },
          },
        });
      }
    }

    const newSearch = {
      populate: {
        user: {
          fields: ['id', 'code', 'nickName']
        }
      },
      sort: ['updatedAt:desc'],
      filters: {
        $and: andConditions,
      },
      pagination: {
        pageSize,
        page: 1, // Reset when search
      },
    };

    setSavedSearch(newSearch);
    await actions.getItems(newSearch);
    setCurrentPage(1);
  }

  function handleOpenFileExel() {
    try {
      fileRef.current.click();
    } catch (e) {
    }
  }

  function handleSelectExelFile() {
    readXlsxFile(fileRef.current.files[0]).then((rows) => {
      console.log('DEBUG rows', rows);
      delete rows[0];
      setUploadItems(rows);
      setIsOpenUploadModal(true);
      userActions.getItems({
        populate: ['role'],
        pagination: {
          limit: 1000
        }
      });

      // Reset value after finish it
      // Reset the input value
      try {
        fileRef.current.value = '';
      } catch (e) {}
    });
  }

  async function handleOpenAssignModal() {
    setIsOpenAssignModal(true);
    userActions.getItems({
      populate: ['role'],
      pagination: {
        limit: 1000
      }
    });
  }

  async function handleImportTasks() {
    const assignedUser = uploadForm.getFieldsValue()?.userId;
    const importType = uploadForm.getFieldsValue()?.importType;
    setIsImported(false);
    try {
      const data = {
        importType,
        tasks: uploadItems.map(item => {
          const contractTo = item[7] ? moment(item[7], CONFIG.exelDateFormat) : null;
          const contractFrom = contractTo ? contractTo.clone().subtract({years: 1}) : null;
          return {
            plateNumber: item[0] ? String(item[0]) : null,
            frameNumber: item[1] ? String(item[1]) : null,
            machineNumber: item[2] ? String(item[2]) : null,
            vehicleLine: item[3],
            customerName: item[4],
            customerAddress: item[5],
            customerPhoneNumber: removeLeadingZero(String(item[6])),
            contractFrom: contractFrom ? contractFrom.format('YYYY-MM-DD').valueOf() : null,
            contractTo: contractTo ? contractTo.format('YYYY-MM-DD').valueOf() : null,
            seatNumber: Number(item[8]),
            contractPrice: item[9] ? (Number(item[9]) * 1000) : null,
            note: item[10] || null,
            updatedAt: moment().toISOString(),
            user: assignedUser || null,
          };
        }).filter(item => !!item),
      };

      const response = await customApiActions.customPost('uploadTasks', data);
      const importedMapping = response.importedMapping;

      const newUploadItems = uploadItems.filter(item => !!item).map((item, index) => {
        return {
          ...item,
          importStatus: importedMapping[String(index)]
        }
      });

      console.log('DEBUG newUploadItems', newUploadItems);

      const results = Object.keys(importedMapping).map(keyName => {
        return importedMapping[keyName].type;
      })

      setImportResults(results);
      setUploadItems(newUploadItems);
      message.success(`Tải dữ liệu thành công. Vui lòng kiểm tra ở cột Kết quả upload!`);
    } catch (e) {
      console.error('DEBUG', e);
      message.error('Có lỗi khi tải lên dữ liệu');
    }
    setIsImported(true);
    form.submit();
  }

  function handleProcessTask(task) {
    setCurrentTask(task);
    setIsOpenTaskModal(true);
    console.log('DEBUG task', task);
  }

  async function handleDeleteAllTask() {
    const agree = window.confirm('Bạn chắc chắn muốn xóa tất cả dữ liệu trên trang chứ?');
    if (!agree) {
      return;
    }

    console.log('TODO Delete', data.list);

    try {
      await Promise.all(data.list.map(item => {
        return actions.deleteItem(item.id);
      }));

      message.success('Xóa dữ liệu cả trang thành công');
    } catch (e) {
      message.error('Có lỗi khi xóa dữ liệu cả trang');
    } finally {
      // Reload items
      await actions.getItems({
        ...savedSearch,
      });
    }
  }

  async function handleDeleteTask(task) {
    const agree = window.confirm('Bạn chắc chắn muốn xóa chứ?');
    if (!agree) {
      return;
    }

    await actions.deleteItem(task.id);

    // Reload items
    await actions.getItems({
      ...savedSearch,
    });
  }

  useEffect(() => {
    if (data.detail) {
      setCurrentTask(data.detail);
    }

    if (data && data.list && data.detail) {
      const newList = data.list.map(item => {
        if (item.id === data.detail.id) {
          item = {
            ...data.detail,
          };
        }

        return item;
      });

      actions.manualSetData({
        ...data,
        list: [
          ...newList,
        ],
      });
    }
    // reloadTableItems();
  }, [data.detail]);

  async function handleChangeTaskStatus(status, updatedFields) {
    try {
      await actions.updateItem(currentTask.id, {
        ...currentTask,
        ...updatedFields,
        taskStatus: status,
        updatedAt: moment().toISOString(),
      });
      await actions.getItem(currentTask.id, {
        populate: '*'
      });
      message.success('Thay đổi trạng thái thành công');
    } catch (e) {
      message.error('Lỗi khi thay đổi trạng thái');
    }
  }

  async function onTaskUpdate(values) {
    console.log('DEBUG values', values);
    const clone = {
      ...values,
    };
    clone.contractFrom = clone.contractFrom ? moment(clone.contractFrom.toString()).format(CONFIG.apiDateFormat).valueOf() : null;
    clone.contractTo = clone.contractTo ? moment(clone.contractTo.toString()).format(CONFIG.apiDateFormat).valueOf() : null;

    await actions.updateItem(currentTask.id, {
      ...currentTask,
      ...clone,
      user: values.userId || null,
      updatedAt: moment().toISOString(),
    });
    await actions.getItem(currentTask.id, {
      populate: ['user'],
    });
    message.success('Cập nhật thông tin thành công');
  }

  function handleNextTask() {
    setCurrentTask({
      ...data.list[activeIndex + 1],
    });
    setActiveIndex(activeIndex + 1);
  }

  function handlePreviousTask() {
    setCurrentTask({
      ...data.list[activeIndex - 1],
    });
    setActiveIndex(activeIndex - 1);
  }

  async function getTasksForMe() {
    try {
      await customApiActions.customPost('assignMe');
      await actions.getItems({
        ...savedSearch,
      });
      message.success('Đã lấy thêm 10 đơn hàng');
    } catch (e) {
      console.log(e);
      message.error('Có lỗi khi lấy đơn hàng');
    }
  }

  async function handleBulkConfirm() {
    const updatedItems = [];
    for (let i = 0; i < data.list.length; i++) {
      try {
        const item = data.list[i];
        await actions.updateItem(item.id, {
          taskStatus: 'MANAGER_CONFIRMED',
        });
        updatedItems.push(item);
      } catch (e) {

      }
    }

    await actions.getItems({
      ...savedSearch,
    });
    message.success(`Đã phê duyệt ${updatedItems.length} items`);
  }

  async function handleInputShipData() {
    setIsShowModalInputShip(true);
  }

  async function handlePrintCurrentPage() {
    // const updatedItems = [];
    // for (let i = 0; i < data.list.length; i++) {
    //   try {
    //     const item = data.list[i];
    //     await actions.updateItem(item.id, {
    //       ...item,
    //       taskStatus: 'PRINTED',
    //     });
    //     updatedItems.push(item);
    //   } catch (e) {
    //
    //   }
    // }
    //
    // const createRes = await printHistoryActions.createItem({
    //   description: 'Printed at ' + moment().format(CONFIG.apiDatetimeFormat).valueOf(),
    //   user: currentUser.id,
    //   tasks: updatedItems.map(item => item.id),
    // });
    //
    // await exportTasksForPrint(updatedItems, 'Print export - ' + moment().format(CONFIG.apiDatetimeFormat).valueOf());
    // message.success('Đã chuyển trạng thái sang đã in');
    // history.push(`/print-histories/view/${createRes.id}`);

    try {
      // Create an array of promises for updating all items concurrently
      const updatePromises = data.list.map(item =>
          actions.updateItem(item.id, {
            ...item,
            taskStatus: 'PRINTED',
          }).then(() => {
            message.success('Đã chuyển trạng thái sang đã in cho KH: ' + item.customerName);
            return item;
          }) // Return the item on successful update
              .catch(() => null) // Handle failure silently by returning null
      );

      // Wait for all updates to finish
      const updatedItems = (await Promise.all(updatePromises)).filter(item => item !== null);

      // Create a print history entry
      const createRes = await printHistoryActions.createItem({
        description: 'Printed at ' + moment().format(CONFIG.apiDatetimeFormat).valueOf(),
        user: currentUser.id,
        tasks: updatedItems.map(item => item.id),
      });

      // Export tasks for printing
      await exportTasksForPrint(updatedItems, 'Print export - ' + moment().format(CONFIG.apiDatetimeFormat).valueOf());

      // Show success message and navigate to the print history view
      message.success('Đã chuyển trạng thái sang đã in');
      history.push(`/print-histories/view/${createRes.id}`);
    } catch (e) {
      console.error('Error during the print process', e);
      message.error('Có lỗi xảy ra khi in');
    }
  }

  async function handlePrintSingleTask() {
    // await actions.updateItem(item.id, {
    //   ...current,
    //   taskStatus: 'PRINTED',
    // });

    const updatedItems = [currentTask];
    try {
      await actions.updateItem(currentTask.id, {
        ...currentTask,
        taskStatus: 'PRINTED',
      });
    } catch (e) {}


    const createRes = await printHistoryActions.createItem({
      description: 'Printed at ' + moment().format(CONFIG.apiDatetimeFormat).valueOf(),
      user: currentUser.id,
      tasks: updatedItems.map(item => item.id),
    });

    await exportTasksForPrint(updatedItems, 'Print export - ' + moment().format(CONFIG.apiDatetimeFormat).valueOf());
    message.success('Đã chuyển trạng thái sang đã in');
    history.push(`/print-histories/view/${createRes.id}`);
  }

  async function handleShipTasks() {
    console.log('DEBUG shipItems', shipItems);
    const items = shipItems.split('\n').filter(item => !!item).map(item => item.trim());
    console.log('DEBUG items', items);

    const res = await actions.customGet('', {
      populate: '*',
      filters: {
        contractNo: {
          $in: items
        }
      }
    });

    const tasks = res.data.data;
    const updatedItems = [];
    for (let i = 0; i < tasks.length; i++) {
      try {
        const item = tasks[i];
        await actions.updateItem(item.id, {
          ...item,
          taskStatus: 'SHIPPED',
        });
        updatedItems.push(item);
      } catch (e) {

      }
    }

    const createRes = await shipHistoryActions.createItem({
      description: 'Shipped at ' + moment().format(CONFIG.apiDatetimeFormat).valueOf(),
      user: currentUser.id,
      tasks: updatedItems.map(item => item.id),
    });

    await exportTasksForShip(updatedItems, 'Ship export - ' + moment().format(CONFIG.apiDatetimeFormat).valueOf());
    message.success('Đã chuyển trạng thái sang đã ship');
    history.push(`/ship-histories/view/${createRes.id}`);
  }

  async function handleAssignCurrentPageForStaff() {
    const assignStaff = assignForm.getFieldsValue().userId;

    if (assignStaff) {
      for (let i = 0; i < data.list.length; i++) {
        try {
          const item = data.list[i];
          await actions.updateItem(item.id, {
            ...item,
            user: assignStaff
          });
        } catch (e) {

        }
      }

      await actions.getItems({
        ...savedSearch
      });

      message.success('Đã phân công cho nhân viên được chỉ định');
      setIsOpenAssignModal(false);
    } else {
      message.error('Vui lòng chọn 1 nhân viên');
    }
  }

  async function handleRemoveAssignCurrentPage() {
    // Keep status as current as Van Anh said
    const isConfirm = window.confirm('Bạn có chắn chắn muốn hủy phân công toàn bộ trang hiện tại?');
    if (!isConfirm) {
      return;
    }
    for (let i = 0; i < data.list.length; i++) {
      try {
        const item = data.list[i];
        await actions.updateItem(item.id, {
          ...item,
          user: null
        });
      } catch (e) {

      }
    }

    await actions.getItems({
      ...savedSearch
    });

    message.success('Đã hủy phân công toàn bộ đơn hàng trang hiện tại');
  }

  async function onClickChangeStatusPage(status) {
    console.log('DEBUG onClickChangeStatusPage', status);
    const isConfirm = window.confirm(`Bạn có chắn chắn muốn đổi trạng thái toàn bộ trang hiện tại sang [${CONFIG.taskStatuses.find((item) => item.value === status.key).label}]?`);
    if (!isConfirm) {
      return;
    }
    for (let i = 0; i < data.list.length; i++) {
      try {
        const item = data.list[i];
        await actions.updateItem(item.id, {
          ...item,
          taskStatus: status.key,
        });
      } catch (e) {

      }
    }

    await actions.getItems({
      ...savedSearch
    });

    message.success('Đã chuyển trạng thái toàn bộ trang hiện tại');
  }

  function getBgByType(type) {
    if (type === 'error') {
      return '#efcfcf';
    }

    if (type === 'ignored') {
      return '#bebebe';
    }

    if (type === 'updated') {
      return '#c9ffb1';
    }

    if (type === 'inserted') {
      return '#c4f4ff';
    }

    return ''
  }

  const menuChangeStatusPage = CONFIG.taskStatuses
  .filter(item => {
    return CONFIG.allowBulkChangeStatuses.includes(item.value)
  })
  .map(item => {
    return {
      label: item.label,
      key: item.value
    }
  });

  function getCellColor(serverRecord, field) {
    if ((serverRecord?.data || []).includes(field)) {
      return '#faad14';
    }

    return ''
  }

  return (
      <AuthLayout pageTitle={'Danh sách công việc'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="listStatuses">
                <div className={`status ${(String(status) === 'all') ? 'active' : 'noActive'}`} key={'all'}
                     onClick={() => {
                       setActiveIndex(null);
                       form.setFieldValue('taskStatus', ['all']);
                       form.submit();
                     }}
                >
                  <FaBorderAll size={20}/>
                  <div className="label">Tất cả</div>
                  <span data-attribute={'all'}>-</span>
                </div>

                {CONFIG.taskStatuses.map((taskStatus) => {
                  if (!CONFIG.allowStatusByRoles[currentRole]?.includes(taskStatus.value)) {
                    return;
                  }
                  return (
                      <div className={`status ${((String(taskStatus.value)) === String(status)) ? 'active' : 'noActive'}`} key={taskStatus.value}
                           onClick={() => {
                             setActiveIndex(null);
                             form.setFieldValue('taskStatus', [taskStatus.value]);
                             form.submit();
                           }}
                      >
                        {taskStatus.icon}
                        <div className="label">{taskStatus.label}</div>
                        <span data-attribute={taskStatus.value}>-</span>
                      </div>
                  );
                })}
              </div>

              {/*Upload modal*/}
              <Modal
                  width={'100%'}
                  title={null}
                  closeIcon={null}
                  open={isOpenUploadModal}
                  onCancel={() => {
                    fileRef.current.value = '';
                    setIsImported(false);
                    setUploadItems([]);
                    setImportResults(null);
                    setIsOpenUploadModal(false);
                  }}
                  onOk={handleImportTasks}
                  okText={'Tải lên dữ liệu'}
                  cancelText={isImported ? 'Đóng modal' : 'Hủy'}
                  okButtonProps={{ style: { display: isImported ? 'none' : '' } }}
              >
                <div className={`modalContent`}>
                  {!!importResults && (
                      <Fragment>
                        <div style={{
                          padding: '10px',
                          border: '1px solid black',
                        }}>
                          <strong>Kết quả import dữ liệu:</strong>
                          <br/>
                          Số records được thêm mới: {importResults.filter(name => name === 'inserted').length} <br/>
                          Số records được cập nhập: {importResults.filter(name => name === 'updated').length} <br/>
                          Số records bị thất bại: {importResults.filter(name => name === 'error').length} <br/>
                          Số records bỏ qua do đã phê duyệt: {importResults.filter(name => name === 'ignored').length} <br/>
                        </div>
                        <br/>
                      </Fragment>
                  )}
                  <Form
                      form={uploadForm}
                      colon={false}
                      layout="vertical"
                      style={{
                        width: '100%',
                      }}
                      initialValues={{
                        userId: null,
                        importType: 'SAFE',
                      }}
                  >
                    <Form.Item
                        name={'userId'}
                        label={'Chỉ định nhân viên xử lý'}
                    >
                      <Select
                          style={{width: '100%'}}
                          options={[
                            ...(userData?.list || [])
                            .filter(item => item?.role?.type === 'staff')
                            .map(item => {
                              return {
                                value: item.id,
                                label: `${item.nickName} - ${item.code || ''}`,
                              };
                            }),
                          ]}/>
                    </Form.Item>

                    <Form.Item
                        name={'importType'}
                        label={'Cách thức import dữ liệu'}
                    >
                      <Select
                          style={{width: '100%'}}
                          options={[
                            {
                              value: 'ALL',
                              label: `Cập nhật tất cả`,
                            },
                            {
                              value: 'SAFE',
                              label: `Không cập nhật các đơn: Đã chốt đơn, Đã phê duyệt, Đã in, Đã ship, Đơn hoàn trả`,
                            }
                          ]}/>
                    </Form.Item>
                  </Form>
                  <table className={'tableContent modalTable'}>
                    <thead>
                    <tr>
                      <th className={'no'}>STT</th>
                      <th>Biển số</th>
                      <th>Số khung</th>
                      <th>Số máy</th>
                      <th>Dòng xe</th>
                      <th>Họ tên</th>
                      <th>Địa chỉ</th>
                      <th>SĐT</th>
                      <th>Hạn BH</th>
                      <th>Số chỗ</th>
                      <th>Giá trị</th>
                      <th>Ghi chú chung</th>
                      <th>Kết quả upload</th>
                    </tr>
                    </thead>
                    <tbody>
                    {uploadItems?.map((item, index) => {
                      return (
                          <tr key={item.id} style={{
                            backgroundColor: getBgByType(item?.importStatus?.type)
                          }}>
                            <td className={'no'}>
                              {index}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'plateNumber')}}>
                              {item[0]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'frameNumber')}}>
                              {item[1]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'machineNumber')}}>
                              {item[2]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'vehicleLine')}}>
                              {item[3]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'customerName')}}>
                              {item[4]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'customerAddress')}}>
                              {item[5]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'customerPhoneNumber')}}>
                              0{item[6]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'contractTo')}}>
                              {item[7]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'seatNumber')}}>
                              {item[8]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'contractPrice')}}>
                              {item[9]}
                            </td>
                            <td style={{backgroundColor: getCellColor(item?.importStatus, 'note')}}>
                              {item[10]}
                            </td>
                            <td>
                              {item?.importStatus?.text}

                              {/*{item.importStatus && !item.importStatus.success && (*/}
                              {/*    <Fragment>*/}
                              {/*      <br/><br/>*/}
                              {/*      {JSON.stringify(item.importStatus)}*/}
                              {/*    </Fragment>*/}
                              {/*)}*/}
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
              </Modal>

              {/*Assign data modal*/}
              <Modal
                  width={'500px'}
                  title={null}
                  closeIcon={null}
                  open={isOpenAssignModal}
                  onCancel={() => {
                    setIsOpenAssignModal(false);
                  }}
                  onOk={handleAssignCurrentPageForStaff}
                  okText={'Chia dữ liệu cho nhân viên'}
              >
                <div className={`assignStaffModal`}>
                  <Form
                      form={assignForm}
                      colon={false}
                      layout="vertical"
                      style={{
                        width: '100%',
                      }}
                      initialValues={{
                        userId: null,
                      }}
                  >
                    <Form.Item
                        name={'userId'}
                        label={'Chỉ định nhân viên xử lý'}
                        rules={[{required: true}]}
                    >
                      <Select
                          style={{width: '100%'}}
                          options={[
                            ...(userData?.list || [])
                            .filter(item => item?.role?.type === 'staff')
                            .map(item => {
                              return {
                                value: item.id,
                                label: `${item.nickName} - ${item.code || ''}`,
                              };
                            }),
                          ]}/>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>

              {/*Modal input shipped code*/}
              <Modal
                  width={'400px'}
                  title={null}
                  closeIcon={null}
                  open={isShowModalInputShip}
                  onCancel={() => {
                    setShipItems(null);
                    setIsShowModalInputShip(false);
                  }}
                  onOk={handleShipTasks}
                  okText={'Chuyển trạng trạng thái thành đã ship'}
              >
                <Form
                    layout={'vertical'}
                >
                  <Form.Item
                      name={'shipData'}
                      label={'Mã đơn hàng chuẩn bị gửi Viettel Post'}
                      rules={[{required: true}]}
                  >
                    <Input.TextArea rows={10} onChange={(e) => {
                      setShipItems(e.target.value);
                    }}/>
                  </Form.Item>
                </Form>
                <br/>
              </Modal>

              <TaskModal
                  isOpenTaskModal={isOpenTaskModal}
                  onCancel={() => {
                    actions.getItems(savedSearch);
                    setIsOpenTaskModal(false);
                  }}
                  data={data}
                  currentTask={currentTask}
                  onTaskUpdate={onTaskUpdate}
                  userData={userData}
                  handleChangeTaskStatus={handleChangeTaskStatus}
                  activeIndex={activeIndex}
                  handlePreviousTask={handlePreviousTask}
                  handleNextTask={handleNextTask}
                  handlePrintSingleTask={handlePrintSingleTask}
              />

              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">Danh sách công việc</div>

                  <Space>
                    {currentRole === 'printer' && (
                        <Fragment>
                          <Space>
                            {String(status) === 'SALE_SUCCESS' && (
                                <Fragment>
                                  <Button type={'primary'} className={'flexCenter'}
                                          onClick={handleBulkConfirm}
                                  >
                                    <AiOutlineCheckCircle size={20}/>
                                    <span>Phê duyệt trang hiện tại</span>
                                  </Button>
                                </Fragment>
                            )}
                          </Space>
                        </Fragment>
                    )}


                    {currentRole === 'manager' && (
                        <Fragment>
                          <Space>
                            <Button type={'primary'} className={'flexCenter'}
                                    onClick={handleOpenFileExel}
                            >
                              <AiOutlineCloudUpload size={20}/>
                              <span>Nhập từ exel</span>
                              <input onChange={handleSelectExelFile} ref={fileRef} style={{display: 'none'}} type="file" accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}/>
                            </Button>
                            <a href={String(sampleImportUrl)} target={'_blank'}>
                              <Button type={'primary'} className={'flexCenter'}>
                                <AiOutlineCloudDownload size={20}/>
                                <span>Tải file mẫu</span>
                              </Button>
                            </a>
                            <Button type={'primary'} className={'flexCenter'}
                                    onClick={handleOpenAssignModal}
                            >
                              <BiSolidUserVoice size={20}/>
                              <span>Phân công NV (trang hiện tại)</span>
                            </Button>


                            <Dropdown menu={{ items: menuChangeStatusPage, onClick: onClickChangeStatusPage }} trigger={['click']}>
                              <Button type={'primary'} className={'flexCenter'}>
                                <BiSolidUserVoice size={20}/>
                                <span>Đổi trạng thái (trang hiện tại)</span>
                              </Button>
                            </Dropdown>

                            <Button type={'primary'} className={'flexCenter'}
                                    onClick={handleRemoveAssignCurrentPage}
                            >
                              <BsArrowReturnLeft size={20}/>
                              <span>Hủy phân công (trang hiện tại)</span>
                            </Button>

                            {String(status) === 'SALE_SUCCESS' && (
                                <Fragment>
                                  <Button type={'primary'} className={'flexCenter'}
                                          onClick={handleBulkConfirm}
                                  >
                                    <AiOutlineCheckCircle size={20}/>
                                    <span>Phê duyệt trang hiện tại</span>
                                  </Button>
                                </Fragment>
                            )}

                            {String(status) === 'MANAGER_CONFIRMED' && (
                                <Fragment>
                                  <Button type={'primary'} className={'flexCenter'}
                                          onClick={handlePrintCurrentPage}
                                  >
                                    <AiOutlinePrinter size={20}/>
                                    <span>In trang hiện tại</span>
                                  </Button>
                                </Fragment>
                            )}

                            {String(status) === 'SHIPPED' && (
                                <Fragment>
                                  <Button type={'primary'} className={'flexCenter'}
                                          onClick={handleInputShipData}
                                  >
                                    <FiPackage size={20}/>
                                    <span>Nhập đơn đã ship</span>
                                  </Button>
                                </Fragment>
                            )}
                          </Space>
                        </Fragment>
                    )}
                  </Space>
                </div>

                <div className="tableSearch">
                  <Form
                      form={form}
                      onFinish={onFinishSearch}
                      colon={false}
                      layout="vertical"
                      style={{
                        width: '100%',
                      }}
                      initialValues={{
                        taskStatuses: ['all'],
                        userId: null,
                      }}
                      className={'fiveColumns'}
                  >
                    <Form.Item
                        name={'plateNumber'}
                        label={'Biển số'}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'customerPhoneNumber'}
                        label={'SĐT'}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'contractNo'}
                        label={'Mã hợp đồng'}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'userId'}
                        label={'NV Phụ trách'}
                    >
                      <Select
                          style={{width: '100%'}}
                          options={[
                            {value: null, label: 'Tất cả'},
                            ...(userData?.list || [])
                            .filter(item => item?.role?.type === 'staff')
                            .map(item => {
                              return {
                                value: item.id,
                                label: `${item.nickName} - ${item.code || ''}`,
                              };
                            }),
                          ]}/>
                    </Form.Item>

                    <Form.Item
                        name={'note'}
                        label={'Ghi chú'}
                    >
                      <Input/>
                    </Form.Item>

                    <Form.Item
                        style={{display: 'none'}}
                        name={'taskStatus'}
                        label={'Trạng thái'}
                    >
                      <Select
                          style={{width: '100%'}}
                          options={[CONFIG.allOption, ...CONFIG.taskStatuses]}/>
                    </Form.Item>

                    {/*<Form.Item name="Trạng thái" label={'Ngày tạo'}>*/}
                    {/*  <DatePicker.RangePicker format={'DD-MM-YYYY'} style={{width: '100%'}}/>*/}
                    {/*</Form.Item>*/}

                    <Form.Item className={'submitRow'}>
                      <Button block type="primary" htmlType="submit">Lọc dữ liệu</Button>
                    </Form.Item>
                  </Form>
                </div>

                {(!data.list || !data.list.length) && (
                    <div className="noContent" style={{
                      display: 'flex',
                    }}>
                      <div className="noContent">
                        Không có dữ liệu.
                      </div>

                      {String(status) === 'NEW' && (
                          <Button onClick={getTasksForMe} block type={'primary'}>Lấy thêm đơn để xử lý</Button>
                      )}
                    </div>
                )}

                {!!data.list && !!data.list.length && (
                    <Fragment>

                      <TableRecords
                          data={data}
                          pageSize={pageSize}
                          activeIndex={activeIndex}
                          currentPage={currentPage}
                          setActiveIndex={setActiveIndex}
                          handleProcessTask={handleProcessTask}
                          handleDeleteTask={handleDeleteTask}
                          setPageSize={setPageSize}
                          setCurrentPage={setCurrentPage}
                          savedSearch={savedSearch}
                          setSavedSearch={setSavedSearch}
                          actions={actions}
                          handleDeleteAllTask={handleDeleteAllTask}
                      />
                    </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
