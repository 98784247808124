import './App.module.scss';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import AuthPage from './pages/Auth/AuthPage';
import AuthCallbackPage from './pages/Auth/AuthCallbackPage';
import {useEffect, useState} from 'react';
import {ConfigProvider, Spin} from 'antd';
import DashboardPage from './pages/Dashboard/DashboardPage';
import UsersPage from './pages/Users/UsersPage';
import viVN from 'antd/locale/vi_VN';
import 'dayjs/locale/vi';

import styles from './App.module.scss';
import UserUpdatePage from './pages/Users/UserUpdatePage';
import LoginPage from './pages/Auth/LoginPage';
import {NoAuthLayout} from './components/layouts/NoAuthLayout';
import TaskUpdatePage from './pages/Tasks/TaskUpdatePage';
import TasksPage from './pages/Tasks/TasksPage';
import PrintHistoryViewPage
  from './pages/PrintHistories/PrintHistoryViewPage';
import PrintHistoriesPage from './pages/PrintHistories/PrintHistoriesPage';
import useStrapiRest from './hooks/useStrapiRest';
import useCurrentUser from './hooks/useCurrentUser';
import moment from 'moment';
import ShipHistoriesPage from './pages/ShipHistories/ShipHistoriesPage';
import ShipHistoryUpdatePage from './pages/ShipHistories/ShipHistoryUpdatePage';
import {getCurrentUser} from './helper';
import PrintHistoryPrintPage from './pages/PrintHistories/PrintHistoryPrintPage';
import SeatPresetsPage from './pages/SeatPresets/SeatPresetsPage';
import SeatPresetUpdatePage from './pages/SeatPresets/SeatPresetUpdatePage';

document.addEventListener('keydown', e => {
  if (document.body.getAttribute('loading') === 'true') {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
  }
});

function App() {
  const [actionsToday, dataToday] = useStrapiRest('tasks');
  useEffect(() => {
    (async () => {
      const currentUser = getCurrentUser();

      if (!currentUser) {
        return;
      }
      const filters = {
        updatedAt: {
          $gt: moment().startOf('week').toISOString(),
          $lt: moment().endOf('week').toISOString()
        }
      }

      if (currentUser?.role?.type !== 'manager' && currentUser?.role?.type !== 'printer') {
        filters.user = {
          id: {
            $eq: currentUser.id
          }
        };
      }

      const response = await actionsToday.getItems({
        noLoading: 1,
        fields: [
          'id',
          'taskStatus',
        ],
        pagination: {
          limit: 1000
        },
        filters
      });

      const status = {};
      response.list.forEach(item => {
        status[item.taskStatus] = status[item.taskStatus] || 0;
        status[item.taskStatus]++;
      });

      let total = 0;
      Object.keys(status).forEach(key => {
        try {
          total = total + status[key];
          document.querySelector(`[data-attribute=${key}]`).innerHTML = `(${status[key]})`;
        } catch (e) {}
      });

      try {
        document.querySelector(`[data-attribute='all']`).innerHTML = `(${total})`;
      } catch (e) {}
    })();
  }, []);

  const history = useHistory();
  useEffect(() => {

  }, [history?.location]);

  return (
      <div className={styles.moduleWrapper}>
        <div className="loadingOverlay">
          <div className="content">
            <Spin size="large"/>
          </div>
        </div>

        <ConfigProvider
            locale={viVN}
            theme={{
              components: {
                Button: {
                  colorPrimary: '#000',
                  algorithm: true, // Enable algorithm
                },
                Checkbox: {
                  colorPrimary: '#000',
                  algorithm: true, // Enable algorithm
                },
              },
              // algorithm: theme.darkAlgorithm
            }}>
          <Router>
            <Switch>
              <Redirect exact from={'/'} to={'/dashboard'}/>

              <Route exact path="/dashboard">
                <DashboardPage/>
              </Route>

              <Route exact path="/users">
                <UsersPage/>
              </Route>

              <Route exact path={['/users/edit/:id', '/users/edit']}>
                <UserUpdatePage/>
              </Route>

              <Route exact path="/seat-presets">
                <SeatPresetsPage/>
              </Route>

              <Route exact path={['/seat-presets/edit/:id', '/seat-presets/edit']}>
                <SeatPresetUpdatePage/>
              </Route>

              <Route exact path="/print-histories">
                <PrintHistoriesPage/>
              </Route>

              <Route exact path={['/print-histories/view/:id']}>
                <PrintHistoryViewPage/>
              </Route>

              <Route exact path={['/print-histories/print/:id']}>
                <PrintHistoryPrintPage/>
              </Route>

              <Route exact path="/ship-histories">
                <ShipHistoriesPage/>
              </Route>

              <Route exact path={['/ship-histories/view/:id']}>
                <ShipHistoryUpdatePage/>
              </Route>

              <Route exact path="/tasks">
                <TasksPage/>
              </Route>

              <Redirect exact from={'/auth/logout'} to="/auth/login"/>

              <Route path="/auth/callback">
                <AuthCallbackPage/>
              </Route>

              <Redirect exact from={'/auth'} to="/auth/init"/>
              {/*<Redirect exact from={'/auth/login'} to="/auth/init"/>*/}
              <Route path="/auth/init">
                <AuthPage/>
              </Route>
              <Route path="/auth/login">
                <NoAuthLayout>
                  <LoginPage/>
                </NoAuthLayout>
              </Route>
            </Switch>
          </Router>
        </ConfigProvider>
      </div>
  );
}

export default App;
