import CONFIG from '../../config';
import {Button} from 'antd';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

export default function AuthPage() {
  function loginWithRedirect() {
    // eslint-disable-next-line no-restricted-globals
    location.href = `${CONFIG.apiEndpoint}/api/connect/google?callback=${location.origin}/auth/callback`;
  }

  useEffect(() => {
    localStorage.removeItem('user');
  }, [])


  return (
      <div>
        <Helmet>
          <title>Login - BH Express</title>
        </Helmet>


        <center>
          <br/><br/><br/>
          <Button
              style={{minWidth: '200px', maxWidth: '100%'}}
              onClick={() => loginWithRedirect()}
              type="primary">
            Đăng nhập bằng tài khoản Google
          </Button>
        </center>
      </div>
  );
}
