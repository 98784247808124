import {AuthLayout} from '../../components/layouts/AuthLayout';
import {Button, DatePicker, Form, Select} from 'antd';
import {Fragment, useEffect, useState} from 'react';
import CONFIG from '../../config';
import styles from './_page.module.scss';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from '../Tasks/_pageConfig';
import moment from 'moment';
import useCurrentUser from '../../hooks/useCurrentUser';
import dayjs from 'dayjs';
import {numberWithCommas} from '../../helper';

const endpoint = _pageConfig.endpoint;
export default function DashboardPage() {
  const currentUser = useCurrentUser();
  const [statusMappingToday, setStatusMappingToday] = useState({});
  const [userMapping, setUserMapping] = useState({});
  const [moneyMapping, setMoneyMapping] = useState({});
  const [actions, data] = useStrapiRest('custom-api/getQuery');
  const [userActions, userData] = useStrapiRest('users');
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      // await userActions.getItems({
      //   populate: {
      //     role: {
      //       fields: ['id', 'name'],
      //     }
      //   },
      //   limit: 10000,
      //   fields: [
      //     'id',
      //     'nickName',
      //     'email',
      //     // 'role.id',
      //     // 'role.name',
      //     'createdAt'
      //   ]
      // });

      let today = moment();
      await actions.getItems({
        startTime: today.startOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),
        endTime: today.endOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),
      })

      // await actions.getItems({
      //   populate: {
      //     user: {
      //       fields: ['id', 'nickName'],
      //     },
      //   },
      //   fields: [
      //       'id',
      //       'contractPrice',
      //       'taskStatus',
      //   ],
      //   pagination: {
      //     limit: 300000,
      //   },
      //   filters: {
      //     updatedAt: {
      //       $gte: today.startOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),
      //       $lte: today.endOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),
      //     },
      //   },
      // })
    })();
  }, []);

  async function onFinishSearch(values) {
    const clone = {
      ...values,
    };

    console.log('DEBUG clone.date_for_search[0]', clone.date_for_search[0]);

    await actions.getItems({
      startTime: moment(clone.date_for_search[0].toString()).startOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),
      endTime: moment(clone.date_for_search[1].toString()).endOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),

      // populate: {
      //   user: {
      //     fields: ['id', 'nickName'],
      //   },
      // },
      // fields: [
      //   'id',
      //   'contractPrice',
      //   'taskStatus',
      // ],
      // pagination: {
      //   limit: 300000,
      // },
      // filters: {
      //   updatedAt: {
      //     // $gte: clone.date_for_search[0].format(CONFIG.apiDatetimeFormat).valueOf(),
      //     // $lte: clone.date_for_search[1].format(CONFIG.apiDatetimeFormat).valueOf(),
      //     $gte: moment(clone.date_for_search[0].toString()).startOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),
      //     $lte: moment(clone.date_for_search[1].toString()).endOf('day').format(CONFIG.apiDatetimeFormat).valueOf(),
      //   },
      // },
    })
  }

  function changeFilterDate(type) {
    let today = moment();

    if (type === 'yesterday') {
      today = today.subtract(1, 'days');
    }

    let from;
    let to;

    if (type === 'currentWeek') {
      from = dayjs(today.clone().isoWeekday(1).format(CONFIG.apiDateFormat).valueOf(),
          CONFIG.apiDateFormat);

      to = dayjs(today.clone().isoWeekday(7).format(CONFIG.apiDateFormat).valueOf(),
          CONFIG.apiDateFormat);
    } else if (type === 'previousWeek') {
      from = dayjs(today.clone().isoWeekday(1).add({week: -1}).format(CONFIG.apiDateFormat).valueOf(),
          CONFIG.apiDateFormat);

      to = dayjs(today.clone().isoWeekday(7).add({week: -1}).format(CONFIG.apiDateFormat).valueOf(),
          CONFIG.apiDateFormat);
    } else {
      from = dayjs(today.startOf(type).format(CONFIG.apiDateFormat).valueOf(),
          CONFIG.apiDateFormat);

      to = dayjs(today.endOf(type).format(CONFIG.apiDateFormat).valueOf(),
          CONFIG.apiDateFormat);
    }

    form.setFieldValue('date_for_search', [from, to]);
  }

  useEffect(() => {
    setStatusMappingToday({});
    setUserMapping({});
    setMoneyMapping({});

    if (data.list && data.list.length) {
      const status = {};
      const user = {};
      const money = {};
      data.list.forEach(item => {
        status[item.taskStatus] = status[item.taskStatus] || 0;
        status[item.taskStatus]++;

        if (!item.user) {
          item.user = {
            id: 0,
            nickName: 'Chưa phân công',
          };
        }

        // Status
        user[item.user.id] = user[item.user.id] || {};
        user[item.user.id].user = item.user;
        user[item.user.id][item.taskStatus] = user[item.user.id][item.taskStatus] || 0;
        user[item.user.id][item.taskStatus]++;

        // Money
        money[item.user.id] = money[item.user.id] || {};
        money[item.user.id].user = item.user;
        money[item.user.id][item.taskStatus] = money[item.user.id][item.taskStatus] || 0;
        money[item.user.id][item.taskStatus] = money[item.user.id][item.taskStatus] + (item.contractPrice || 0);
      });

      setStatusMappingToday(status);
      setUserMapping(user);
      setMoneyMapping(money);
    }
  }, [data]);

  return (
      <AuthLayout pageTitle={'Hệ thống BH Express'}>
        <div className={'pageWrapper'}>
          <div className="pageContent">
            <div className={styles.moduleWrapper}>
              <Form
                  form={form}
                  onFinish={onFinishSearch}
                  colon={false}
                  layout="vertical"
                  style={{
                    width: '100%',
                  }}
                  initialValues={{
                    date_for_search: [
                      dayjs(moment().format(CONFIG.apiDateFormat).valueOf(),
                          CONFIG.apiDateFormat),
                      dayjs(moment().format(CONFIG.apiDateFormat).valueOf(),
                          CONFIG.apiDateFormat),
                    ],
                    user: null,
                  }}
                  className={'twoColumns'}
              >
                <Form.Item rules={[{required: true}]} name="date_for_search" className={'formFieldDate'}
                           label={(
                               <Fragment>
                                 <div className={'labelDate'}>
                                   <span>Thời gian</span>
                                   <div className="quickFilter">
                                     <div onClick={() => changeFilterDate('yesterday')}>Hôm qua</div>
                                     <div onClick={() => changeFilterDate('day')}>Hôm nay</div>
                                     <div onClick={() => changeFilterDate('currentWeek')}>Tuần hiện tại</div>
                                     <div onClick={() => changeFilterDate('previousWeek')}>Tuần trước</div>
                                     <div onClick={() => changeFilterDate('month')}>Tháng hiện tại</div>
                                   </div>
                                 </div>
                               </Fragment>
                           )}>
                  <DatePicker.RangePicker format={CONFIG.humanDateFormat}
                                          style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item className={'submitRow'} style={{
                  maxWidth: '50%'
                }}>
                  <Button type="primary" block htmlType="submit">Tra cứu</Button>
                </Form.Item>
              </Form>

              <center><h2>Theo trạng thái</h2></center>
              <div className="statuses">
                {CONFIG.taskStatuses.map(status => {
                  return (
                      <div className={'status'}>
                        <div className="name">{status.label}</div>
                        <div className="counter">{statusMappingToday[status.value] || 0}</div>
                      </div>
                  );
                })}
              </div>

              <br/>

              <center><h2>Theo nhân viên</h2></center>
              <table className={'tableContent'}>
                <thead>
                <tr>
                  <th className={'no'}>STT</th>
                  <th>Nhân viên</th>
                  {/*<th>Email</th>*/}
                  {CONFIG.taskStatuses.map(taskStatus => {
                    return (
                        <th>
                          {taskStatus.label}
                        </th>
                    )
                  })}
                </tr>
                </thead>
                <tbody>
                {Object.keys(userMapping)?.map((userId, index) => {
                  const user = userMapping[userId];
                  const money = moneyMapping[userId];
                  return (
                      <tr key={userId} className={String(userId) === String(currentUser?.id) ? 'activeRow' : '' }>
                        <td className={'no'}>
                          {index + 1}
                        </td>
                        <td>
                          {user.user.nickName}
                        </td>
                        {/*<td>*/}
                        {/*  {user.user.email}*/}
                        {/*</td>*/}
                        {CONFIG.taskStatuses.map(taskStatus => {
                          return (
                              <td>
                                {user[taskStatus.value] || 0} ({numberWithCommas(money[taskStatus.value] || 0) || 0} VNĐ)
                              </td>
                          )
                        })}
                      </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}

