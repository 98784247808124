import moment from 'moment';
import QRCode from 'qrcode';
import * as XLSX from 'xlsx';

export function formatDate(dateString) {
  return moment(dateString).format('DD-MM-YYYY');
}

export function formatUpdatedAt(dateString) {
  return moment(dateString).format('HH:mm:ss DD/MM');
}

export async function genPhoneQRCode(phoneNumber) {
  return new Promise((resolve) => {
    QRCode.toDataURL(`tel:0${phoneNumber}`, (err, url) => {
      resolve(url);
    });
  });
}

export function mappingRoleToLabel(status) {
  const mapping = {
    'role.company-admin': 'Company admin',
    'role.super-admin': 'Super admin',
  };

  return mapping[status] || 'Không xác định';
}

export function exportTasksForPrint(tasks, fileName = 'Export-data-for-print') {
  const formattedItems = tasks.map((item, index) => {
    return {
      'STT (1)': item.contractNo,
      'Tên (2)': item.customerName,
      'Tên update (3)': `${item.customerName} (${item?.user?.code})`,
      'SĐT (4)': item.customerPhoneNumber,
      'Địa chỉ (5)': item.customerAddress,
      'COD (6)': numberWithCommas(item.contractPrice),
      'Kiểm tra (7)': '',
      'Cán bộ (8)': item?.user?.code,
      'Biển số (9)': item.plateNumber,
      'Ngày giờ giao (10)': '',
      'Ghi chú ship (11)': item.printNote,
      'Ngày nối': '',
    }
  });

  const worksheet = XLSX.utils.json_to_sheet(formattedItems);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Print');
  XLSX.writeFile(workbook, fileName + '.xlsx');
}

export function exportTasksForShip(tasks, fileName = 'Export-data-for-ship') {
  const formattedItems = tasks.map((item, index) => {
    return {
      'STT': index + 1,
      'Mã đơn hàng': item.contractNo,
      'Tên người nhận (*)': item.customerName,
      'Số ĐT người nhận (*)': item.customerPhoneNumber,
      'Địa chỉ nhận (*)': item.customerAddress,
      'Tên hàng hóa (*)': 'BẢO HIỂM CHO KHÁCH HÀNG KIỂM TRA',
      'Số lượng': '1',
      'Trọng lượng (gram)  (*)': '100',
      'Giá trị hàng (VND) (*)': item.contractPrice,
      'Tiền thu hộ COD (VND)': item.contractPrice,
      'Loại hàng hóa (*)': 'Tài liệu',
      'Tính chất hàng hóa đặc biệt': '',
      'Dịch vụ  (*)': 'VCBO - Chuyển phát đường bộ',
      'Dịch vụ cộng thêm ': '0',
      'Dài (cm)': '1',
      'Rộng (cm)': '1',
      'Cao (cm)': '1',
      'Người trả cước': 'Người gửi',
      'Yêu cầu khác': '0',
      'Thời gian giao': 'Giờ hành chính'
    }
  })

  const worksheet = XLSX.utils.json_to_sheet(formattedItems);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'ViettelPost');
  XLSX.writeFile(workbook, fileName + '.xlsx');
}

export function getCurrentUser() {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    return null
  }
}

export function numberWithCommas(x) {
  if (!x || !x.toString) {
    return;
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeLeadingZero(str) {
  if (!str || !str.charAt) {
    return;
  }

  if (str.charAt(0) === '0') {
    // If the first character is '0', remove it and return the rest of the string
    return str.substr(1);
  } else {
    // If the first character is not '0', return the original string
    return str;
  }
}
