import dayjs from 'dayjs';

import styles from './_page.module.scss';
import {Button, Form, Pagination} from 'antd';
import {Link, useParams} from 'react-router-dom';
import {Fragment, useEffect, useRef, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import {formatDate, genPhoneQRCode} from '../../helper';

const endpoint = _pageConfig.endpoint;
export default function ShipHistoriesPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [savedSearch, setSavedSearch] = useState({
    populate: '*',
    pagination: {
      pageSize,
      page: currentPage,
    },
  });
  const [actions, data] = useStrapiRest(endpoint);

  useEffect(() => {
    (async () => {
      await actions.getItems({
        sort: ['createdAt:desc'],
        pagination: {
          pageSize,
        },
        populate: '*'
      })
    })();
  }, []);

  return (
      <AuthLayout pageTitle={'Lịch sử ship đơn'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="commonTable">
                <div className="tableTopActions">
                  <div className="title">Lịch sử ship đơn</div>
                </div>

                {(!data.list || !data.list.length) && (
                    <div className="noContent">
                      Không tìm thấy dữ liệu.
                    </div>
                )}

                {!!data.list && !!data.list.length && (
                    <Fragment>
                      <table className={'tableContent'}>
                        <thead>
                        <tr>
                          <th className={'no'}>STT</th>
                          <th>Người in</th>
                          <th>Ngày in</th>
                          <th>Số lượng đơn</th>
                          <th>Ghi chú</th>
                          <th className={'actions'}
                              style={{width: '85px !important'}}>Thao tác
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.list?.map((item, index) => {
                          return (
                              <tr key={item.id}>
                                <td className={'no'}>
                                  {index + 1 + pageSize * (currentPage - 1)}
                                </td>
                                <td>
                                  {item?.user?.nickName}
                                </td>
                                <td>
                                  {formatDate(item.createdAt)}
                                </td>
                                <td>
                                  {item?.tasks?.length}
                                </td>
                                <td>
                                  {item.description}
                                </td>
                                <td className={'actions'}
                                    style={{width: '85px !important'}}>
                                  <Link to={`/${endpoint}/view/${item.id}`}>
                                    <Button type={'primary'}>Xem chi
                                      tiết</Button>
                                  </Link>
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>
                      <div className="tablePagination">
                        <Pagination
                            onShowSizeChange={(current, newPageSize) => {
                              setPageSize(newPageSize);
                            }}
                            onChange={async (page, newPageSize) => {
                              setPageSize(newPageSize);
                              setCurrentPage(page);
                              await actions.getItems({
                                ...savedSearch,
                                pagination: {
                                  page,
                                  pageSize: newPageSize,
                                },
                              });
                            }} current={currentPage} pageSize={pageSize}
                            total={data?.listPagination?.pagination.total}/>
                      </div>
                    </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}

