import React from 'react';

import styles from './NoAuthLayout.module.scss';

export function NoAuthLayout({children, authRequired = true}) {
  return (
      <div className={styles.moduleWrapper}>
        <div className={'wrapper'}>
          <div className="header">
            <div className="logo"></div>
            <div className="title">Bảo Hiểm Express</div>
            <div className="right"></div>
          </div>
          <div className="content">
            {children}
          </div>
          <div className="footer">
            <div className="copyright">Copyright 2023. All Right Reserved.</div>
          </div>
        </div>
      </div>
  );
}
