import CONFIG from '../config';
import {Button, Pagination, Space} from 'antd';
import {Fragment} from 'react';
import {formatDate, formatUpdatedAt} from '../helper';
import useCurrentRole from '../hooks/useCurrentRole';
import moment from 'moment';

export function TableRecords({
                               data,
                               pageSize,
                               activeIndex,
                               currentPage,
                               setActiveIndex,
                               handleProcessTask,
                               handleDeleteTask,
                               setPageSize,
                               setCurrentPage,
                               savedSearch,
                               setSavedSearch,
                               actions,
                               handleDeleteAllTask,
                             }) {

  const currentRole = useCurrentRole();

  return (
      <Fragment>
        <table className={'tableContent'}>
          <thead>
          <tr>
            <th className={'no'}>STT</th>
            <th>Biển số</th>
            {['manager', 'printer'].includes(currentRole) && (
                <th>NV phụ trách</th>
            )}
            <th>Họ tên</th>
            <th>SĐT</th>
            <th>Địa chỉ</th>
            <th>Hạn BH</th>
            <th>Số HĐ</th>
            <th>Ghi chú</th>
            <th>Trạng thái</th>
            <th>Cập nhật lúc</th>
            <th className={'actions'} style={{width: '120px !important'}}>
              <div className={'flexCenter'}>
                <div>Thao tác</div>
                {currentRole === 'manager' && (
                    <div onClick={() => {
                      handleDeleteAllTask && handleDeleteAllTask();
                    }} style={{cursor: 'pointer', color: 'red'}}>Xóa</div>
                )}
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          {data?.list?.map((item, index) => {
            return (
                <tr key={item.id}
                    className={(activeIndex === index) ? 'activeRow' : ''}>
                  <td className={'no'}>
                    {index + 1 + pageSize * (currentPage - 1)}
                  </td>
                  <td>
                    {item.plateNumber}
                  </td>
                  {['manager', 'printer'].includes(currentRole) && (
                      <td>{item?.user?.nickName}</td>
                  )}
                  <td>
                    {item.customerName}
                  </td>
                  <td>
                    {item.customerPhoneNumber}
                  </td>
                  <td>
                    {item.customerAddress}
                  </td>
                  <td>
                    {item.contractTo ? moment(item.contractTo).format(CONFIG.humanDateFormat) : ''}
                  </td>
                  <td>
                    {item.contractNo}
                  </td>
                  <td>{item.note}</td>
                  <td>
                    {CONFIG.taskStatuses.map(status => {
                      if (status.value === item.taskStatus) {
                        return status.label;
                      }
                    })}
                  </td>
                  <td>{formatUpdatedAt(item.updatedAt)}</td>
                  <td className={'actions'} style={{width: '85px !important'}}>
                    <Space>
                      <Button type={'primary'}
                              onClick={() => {
                                setActiveIndex(index);
                                handleProcessTask(item);
                              }}
                      >Xem</Button>

                      {!!handleDeleteTask && currentRole === 'manager' && (
                          <Button type={'default'}
                                  onClick={() => {
                                    handleDeleteTask(item);
                                  }}
                          >Xóa</Button>
                      )}
                    </Space>
                  </td>
                </tr>
            );
          })}
          </tbody>
        </table>
        <div className="tablePagination">
          <Pagination
              onShowSizeChange={(current, newPageSize) => {
                setPageSize(newPageSize);
              }}
              onChange={async (page, newPageSize) => {
                const params = {
                  ...savedSearch,
                  pagination: {
                    page,
                    pageSize: newPageSize
                  }
                };

                if (newPageSize !== pageSize) {
                  params.pagination.page = 1; // Reset to 1 when change size of each page
                  setCurrentPage(1);
                  setPageSize(newPageSize);

                  await actions.getItems(params)
                  setSavedSearch(params);
                } else {
                  await actions.getItems(params)
                  setSavedSearch(params);
                  setCurrentPage(page);
                }

              }} current={currentPage} pageSize={pageSize} total={data?.listPagination?.pagination.total} />
        </div>
      </Fragment>
  );
}
