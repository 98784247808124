import React, {useEffect, useState} from 'react';
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom';
import styles from './AuthLayout.module.scss';
import {FaUserFriends} from 'react-icons/fa';
import {Dropdown, message, Space} from 'antd';
import {BiHappyHeartEyes, BiSolidDownArrow} from 'react-icons/bi';
import {BsFillBuildingFill, BsFillPatchQuestionFill} from 'react-icons/bs';
import {HiOutlineSpeakerphone} from 'react-icons/hi';
import {IoNewspaperOutline} from 'react-icons/io5';
import {
  AiOutlineAreaChart,
  AiOutlinePauseCircle,
  AiOutlinePrinter, AiOutlineShoppingCart,
} from 'react-icons/ai';
import {FcCallback, FcMissedCall, FcShipped} from 'react-icons/fc';
import {MdSmsFailed} from 'react-icons/md';
import {GrDocumentTime, GrInProgress} from 'react-icons/gr';
import {PiKeyReturnFill} from 'react-icons/pi';
import {FiPackage} from 'react-icons/fi';


export function AuthLayout(props) {
  const [valid, setValid] = useState(false);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState('company');
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    try {
      const user = localStorage.getItem('user');
      if (user) {
        setUser(JSON.parse(user));
        setRole(JSON.parse(user).role.type)
      } else {
        setUser(null);
        setRole(null)
      }
    } catch (e) {
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setValid(true);
      try {
        setUser(JSON.parse(localStorage.getItem('user')));
      } catch (e) {
      }
    } else {
      localStorage.setItem('redirectUrl', window.location.href);
      window.location.href = '/auth/login';
    }
  }, []);

  if (!valid) {
    return (
        <div>
          <center>Loading data...</center>
        </div>
    );
  }

  const menuLinks = [
    {
      icon: <AiOutlineAreaChart size={20}/>,
      to: '/dashboard',
      name: 'Thống kê',
      roles: ['manager', 'staff', 'printer'],
    },
    {
      icon: <FaUserFriends size={20}/>,
      to: '/users',
      name: 'Người dùng',
      roles: ['manager']
    },
    {
      icon: <FaUserFriends size={20}/>,
      to: '/seat-presets',
      name: 'Số chỗ',
      roles: ['manager']
    },
    {
      icon: <AiOutlineShoppingCart size={20}/>,
      to: '/tasks',
      name: 'Đơn hàng',
      roles: ['manager', 'staff', 'printer']
    },
    // {
    //   icon: <AiOutlinePauseCircle size={20}/>,
    //   to: '/tasks/NEW',
    //   name: 'Đơn chờ xử lý',
    //   roles: ['manager', 'staff'],
    //   attribute: 'NEW',
    // },
    // {
    //   icon: <GrInProgress size={20}/>,
    //   to: '/tasks/IN_PROGRESS',
    //   name: 'Đơn đang xử lý',
    //   roles: ['manager', 'staff'],
    //   attribute: 'IN_PROGRESS',
    // },
    // {
    //   icon: <FcMissedCall size={20}/>,
    //   to: '/tasks/NO_PICKUP',
    //   name: 'Đơn không nghe máy',
    //   roles: ['manager', 'staff'],
    //   attribute: 'NO_PICKUP',
    // },
    // {
    //   icon: <FcCallback size={20}/>,
    //   to: '/tasks/CALL_LATER',
    //   name: 'Đơn hẹn gọi lại',
    //   roles: ['manager', 'staff'],
    //   attribute: 'CALL_LATER',
    // },
    // {
    //   icon: <MdSmsFailed color={'#ff4d4d'} size={20}/>,
    //   to: '/tasks/SALE_FAIL',
    //   name: 'Đơn khách từ chối',
    //   roles: ['manager', 'staff'],
    //   attribute: 'SALE_FAIL',
    // },
    // {
    //   icon: <BiHappyHeartEyes color={'green'} size={20}/>,
    //   to: '/tasks/SALE_SUCCESS',
    //   name: 'Đơn thành công',
    //   roles: ['manager', 'staff'],
    //   attribute: 'SALE_SUCCESS',
    // },
    // {
    //   icon: <BiHappyHeartEyes color={'green'} size={20}/>,
    //   to: '/tasks/MANAGER_CONFIRMED',
    //   name: 'Đơn đã phê duyệt',
    //   roles: ['manager'],
    //   attribute: 'MANAGER_CONFIRMED',
    // },
    // {
    //   icon: <AiOutlinePrinter color={'green'} size={20}/>,
    //   to: '/tasks/PRINTED',
    //   name: 'Đơn đã in',
    //   roles: ['manager', 'staff'],
    //   attribute: 'PRINTED',
    // },
    // {
    //   icon: <FcShipped color={'green'} size={20}/>,
    //   to: '/tasks/SHIPPED',
    //   name: 'Đơn đã ship',
    //   roles: ['manager', 'staff'],
    //   attribute: 'SHIPPED',
    // },
    // {
    //   icon: <PiKeyReturnFill color={'black'} size={20}/>,
    //   to: '/tasks/RETURNED',
    //   name: 'Đơn hoàn trả',
    //   roles: ['manager', 'staff'],
    //   attribute: 'RETURNED',
    // },
    {
      icon: <GrDocumentTime size={20}/>,
      to: '/print-histories',
      name: 'Lịch sử in',
      roles: ['manager', 'printer'],
    },
    {
      icon: <FiPackage size={20}/>,
      to: '/ship-histories',
      name: 'Lịch sử ship',
      roles: ['manager', 'printer'],
    },
  ];

  function handleLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    history.push('/auth/login');
  }

  const onClick = ({key}) => {
    if (key === 'logout') {
      handleLogout();
    } else {
      history.push('/profile');
    }
  };

  const items = [
    {
      label: 'Đăng xuất',
      key: 'logout',
    },
  ];

  return (
      <div className={styles.moduleWrapper}>
        <div className={'wrapper'}>
          <div className="sidebar">
            <div className="top">
              <div className="logo">
                <Link to={'/'}>BH Express</Link>
              </div>
              <div className="links">
                {menuLinks.map((item, index) => {
                  if (!item.roles.includes(role)) {
                    return;
                  }

                  return (
                      <div key={item.to} className={'link'}>
                        <NavLink href={item.to} to={item.to}
                                 activeClassName="selected">
                          {item.icon}
                          <div className="name">
                            {item.name} <span data-attribute={item.attribute}></span>
                          </div>
                        </NavLink>
                      </div>
                  );
                })}
              </div>
            </div>
            <div className="bottom">
              <div className="copyright">Copyright 2023. All Right Reserved.</div>
            </div>
          </div>
          <div className="content">
            <div className="pageHeading">
              <div className={'pageTitle'}>
                {props.pageTitle || 'Page title'}
              </div>
              <div className="right">
                <div className="topMenus">
                  {menuLinks.map((item, index) => {
                    if (!item.roles.includes(role)) {
                      return;
                    }

                    return (
                        <div key={item.to} className={'link'}>
                          <NavLink href={item.to} to={item.to}
                                   activeClassName="selected">
                            {item.icon}
                            <div className="name">
                              {item.name} <span data-attribute={item.attribute}></span>
                            </div>
                          </NavLink>
                        </div>
                    );
                  })}
                </div>
                <Dropdown menu={{items, onClick}} trigger={['click']}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <div>Tài khoản {user.nickName || user.email}</div>
                      <BiSolidDownArrow size={15}/>
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>

            {props.children}
          </div>
        </div>
      </div>
  );
}
