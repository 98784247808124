import styles from './_page.module.scss';
import {Button, Form, Input, message} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';

const endpoint = _pageConfig.endpoint;
export default function SeatPresetUpdatePage() {
  const [actions, data] = useStrapiRest(endpoint);
  const params = useParams();
  const [id, setId] = useState();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!params.id) {
      return;
    }

    (async () => {
      setId(params.id);
      await actions.getItem(params.id, {
        populate: [],
      });
    })();
  }, [params.id]);

  function onFormLayoutChange() {

  };

  useEffect(() => {
    if (data.detail) {
      form.setFieldsValue({
        ...data.detail,
      });
    }
  }, [JSON.stringify(data?.detail)]);

  async function onFinish(values) {
    try {
      if (!id) {
        await actions.customPost('', {
          data: {
            ...values
          },
        });
        message.success('Tạo mới thành công!');
        history.push(`/${endpoint}`);
      } else {
        await actions.customUpdate(id, {
          data: {
            ...values
          },
        });
        message.success('Cập nhật thành công!');
        history.push(`/${endpoint}`);
      }
    } catch (e) {
      console.error(e);
      message.warning('Có lỗi xảy ra!');
    }
  }

  return (
      <AuthLayout pageTitle={id ? 'Cập nhật người dùng' : 'Thêm mới người dùng'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <Form
                  form={form}
                  onFinish={onFinish}
                  colon={false}
                  layout="vertical"
                  onValuesChange={onFormLayoutChange}
                  style={{
                    width: '100%',
                  }}
                  className={'oneColumns'}
              >
                <Form.Item
                    name={'machineOrFrameNumber'}
                    label={'Số khung/số máy'}
                    rules={[{required: true}]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name={'seat'}
                    label={'Số chỗ'}
                    rules={[{required: true}]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item className={'submitRow'}>
                  <Button block type="primary" htmlType="submit">
                    {id ? 'Cập nhật' : 'Tạo mới'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
