import axios from 'axios';
import CONFIG from '../config';
import {message} from 'antd';

window.counter = 0;

function handleLoading() {
  document.querySelector('body').setAttribute('loading', window.counter > 0 ? 'true' : 'false')
}

export function getAxios() {
  if (!window['custom_axios']) {
    window['custom_axios'] = axios.create({
      baseURL: `${CONFIG.apiEndpoint}`,
    });

    window['custom_axios'].interceptors.request.use(function(config) {
      if (config.url.indexOf('noLoading') < 0) {
        window.counter++;
        handleLoading();
      }

      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    window['custom_axios'].interceptors.response.use(response => {
      if (response.request.responseURL.indexOf('noLoading') < 0) {
        window.counter--;
        handleLoading();
      }

      return response;
    }, error => {
      if (error.request.responseURL.indexOf('noLoading') < 0) {
        window.counter--;
        handleLoading();
      }

      try {
        if (error.request.responseURL.indexOf('manualError') < 0) {
          console.log('DEBUG error', error);
          const errorMessage = error.response.data.error.message;
          if (errorMessage && errorMessage.indexOf('::') < 0) {
            setTimeout(() => {
              message.destroy();
              message.error(errorMessage, 5);
            });
          }
        }
      } catch (e) {
        // No handle
      }

      if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.href = '/auth/login';
        return;
      }

      return Promise.reject(error);
    });
  }

  return window['custom_axios'];
}
