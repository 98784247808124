import {Button, Form, Input, message} from 'antd';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

import styles from './LoginPage.module.scss';
import {getAxios} from '../../services/api';
import {useHistory} from 'react-router-dom';
import CONFIG from '../../config';
import {FcGoogle} from 'react-icons/fc';
import useStrapiRest from '../../hooks/useStrapiRest';

export default function LoginPage() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [actions, data] = useStrapiRest('users');

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('adminRole');
  }, []);

  async function onFinish(values) {
    try {
      const res = await getAxios().post('/api/auth/local?manualError=1', values);
      message.success('Đăng nhập thành công');

      localStorage.setItem('token', res.data.jwt);
      const profile = await actions.customGet('me', {
        populate: ['role']
      });
      localStorage.setItem('user', JSON.stringify(profile.data));

      history.push('/');
    } catch (e) {
      console.error(e);
      message.error('Email/username hoặc mật khẩu không hợp lệ');
    }
  }

  function loginWithGoogle() {
    window.location.href = `${CONFIG.apiEndpoint}/api/connect/google?callback=${window.location.origin}/auth/callback`;
  }

  return (
      <div className={styles.moduleWrapper}>
        <Helmet>
          <title>Đăng nhập - BH Express</title>
        </Helmet>

        <center>
          <h3 style={{
            paddingTop: 0,
            marginTop: 0
          }}>LOGIN</h3>
        </center>

        <Form
            form={form}
            onFinish={onFinish}
            colon={false}
            layout="vertical"
            style={{
              width: '100%',
            }}
        >
          <Form.Item
              name={'identifier'}
              label={'Email / Username'}
              rules={[
                  {required: true},
              ]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
              name={'password'}
              label={'Mật khẩu'}
              rules={[{required: true}]}
          >
            <Input type={'password'}/>
          </Form.Item>

          <Form.Item style={{
            marginTop: '10px'
          }}>
            <Button block type="primary" htmlType="submit">
              Đăng nhập
            </Button>
          </Form.Item>
          <div>
            <Button block onClick={loginWithGoogle}>
              <div className="flexCenter">
                <FcGoogle size={25} />
                <span>Đăng nhập bằng Google</span>
              </div>
            </Button>
            <br/><br/>
          </div>
        </Form>
      </div>
  );
}
