import {getAxios} from '../services/api';
import {useEffect, useState} from 'react';

const useCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    return null
  }
};

export default useCurrentUser;
