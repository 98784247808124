import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber, message,
  Modal,
  Popconfirm,
  Select,
} from 'antd';
import styles from '../pages/Tasks/_page.module.scss';
import CONFIG from '../config';
import {GrInProgress, GrLinkNext, GrLinkPrevious} from 'react-icons/gr';
import {FcCallback, FcMissedCall, FcShipped} from 'react-icons/fc';
import {MdOutlineSmsFailed} from 'react-icons/md';
import {BiArrowBack, BiArrowToRight, BiHappyHeartEyes, BiSkipNext} from 'react-icons/bi';
import {PiSealCheck} from 'react-icons/pi';
import {AiFillPrinter} from 'react-icons/ai';
import useCurrentRole from '../hooks/useCurrentRole';
import {exportTasksForPrint, genPhoneQRCode} from '../helper';
import dayjs from 'dayjs';
import {useEffect, useState, Fragment} from 'react';
import moment from 'moment';
import {IoIosPrint} from 'react-icons/io';
import {AddressModal} from './AddressModal';
import {CiSearch} from 'react-icons/ci';

export function TaskModal({
                            data,
                            isOpenTaskModal,
                            onCancel,
                            currentTask,
                            onTaskUpdate,
                            userData,
                            handleChangeTaskStatus,
                            activeIndex,
                            handlePreviousTask,
                            handleNextTask,
                            handlePrintSingleTask,
                          }) {
  const [taskForm] = Form.useForm();
  const currentRole = useCurrentRole();
  const [qrCodeUrl, setQRCodeUrl] = useState([]);

  const [isShowAddressModal, setIsShowAddressModal] = useState(false);

  useEffect( () => {
    (async () => {
      if (currentTask) {
        const qrCode = await genPhoneQRCode(currentTask.customerPhoneNumber);
        setQRCodeUrl(qrCode);
        taskForm.setFieldsValue({
          ...currentTask,
          contractFrom: currentTask.contractFrom ? dayjs(currentTask.contractFrom) : null,
          contractTo: currentTask.contractTo ? dayjs(currentTask.contractTo) : null,
          userId: currentTask.user ? currentTask.user.id : null,
        })
      } else {
        taskForm.resetFields();
      }
    })()
  }, [currentTask]);

  function handleOnChangeContractFrom(value) {
    taskForm.setFieldValue('contractTo', dayjs(moment(value.toString()).add({years: 1}).toISOString()));
    console.log('DEBUG handleOnChangeContractFrom', value);
  }

  const customerAddress = Form.useWatch('customerAddress', taskForm);
  const customerJsonAddress = Form.useWatch('customerJsonAddress', taskForm);

  const prices = [395, 399, 755, 759, 789];

  return (
      <Modal
          centered
          width={'1300px'}
          title={null}
          closeIcon={null}
          open={isOpenTaskModal}
          onCancel={onCancel}
          footer={null}
          className={styles.moduleWrapper}
      >
        <div className={`taskContent`}>
          {!!currentTask && (
              <Fragment>
                <h1 style={{
                  margin: '0 0'
                }}>
                  <center style={{
                    textTransform: 'uppercase',
                  }}>
                    {CONFIG.taskStatuses.map(status => {
                      if (currentTask.taskStatus === status.value) {
                        return status.label;
                      }
                    })}

                    <span> (Mã HĐ: {currentTask.contractNo || 'Chưa cấp'})</span>
                  </center>
                </h1>
                <div className="actionAndImage">
                  <div className="left">
                    {currentTask.taskStatus === 'NEW' && (
                        <div className={'blankPhone'}>
                          <strong>Chuyển trạng thái <br/> để hiện SĐT</strong>
                        </div>
                    )}
                    {currentTask.taskStatus !== 'NEW' && (
                        <div className={'qrArea'}>
                          <img className={'qrPreview'} data-role={currentRole} style={{border: '1px solid black'}}
                               src={qrCodeUrl} alt="Img"/>
                          <div className="phone" style={{textAlign: 'center', fontSize: '30px'}}>
                            0{currentTask.customerPhoneNumber}
                          </div>
                        </div>
                    )}
                  </div>
                  <div className="right" style={{width: '100%'}}>
                    <div className="taskActions">
                      {CONFIG.taskStatuses.map(status => {
                        if (currentTask.taskStatus === status.value) {
                          return (
                              <Button key={status.value} className={'activeStatus'} style={{cursor: 'default'}} block type={'default'}>{status.icon}{status.label} (hiện tại)</Button>
                          )
                        }

                        if (!CONFIG.allowStatusByRoles[currentRole].includes(status.value)) {
                          return;
                        }

                        return (
                            <Button
                                key={status.value}
                                onClick={async () => {
                                  try {
                                    const values = await taskForm.validateFields();


                                    if (status.value === 'SALE_SUCCESS') {
                                      if (!values.contractPrice) {
                                        message.error('Bạn cần nhập giá tiền đơn hàng khi chốt đơn!');
                                        return;
                                      }

                                      if (values.contractPrice < 100000) {
                                        message.error('Hãy kiểm tra lại giá trị đơn hàng, nhỏ nhất là 100,000 VNĐ!');
                                        return;
                                      }
                                    }

                                    await onTaskUpdate(values);
                                    await handleChangeTaskStatus(status.value, values);
                                  } catch (e) {
                                    alert('Hãy [Cập nhật thông tin] trước khi chuyển trạng thái!');
                                  }
                                }}
                                disabled={!CONFIG.allowStatusByRoles[currentRole].includes(status.value)} block type={'default'}>{status.icon}{status.label}
                            </Button>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className="taskUpdateForm">
                  <Form
                      form={taskForm}
                      onFinish={onTaskUpdate}
                      colon={false}
                      layout="vertical"
                      style={{
                        width: '100%',
                      }}
                      className={'fiveColumns'}
                  >

                    <Form.Item
                        name={'userId'}
                        label={'NV Phụ trách'}
                    >
                      <Select
                          style={{width: '100%'}}
                          options={[
                            {value: null, label: 'Chưa xác định'},
                            ...(userData?.list || []).map(item => {
                              return {
                                value: item.id,
                                label: `${item.nickName} - ${item.code || ''}`,
                              };
                            }),
                          ]}/>
                    </Form.Item>
                    <Form.Item
                        name={'plateNumber'}
                        rules={[{required: true}]}
                        label={'Biển số'}
                    >
                      <Input/>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    name={'frameNumber'}*/}
                    {/*    label={'Số khung'}*/}
                    {/*>*/}
                    {/*  <Input disabled={true}/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item*/}
                    {/*    name={'machineNumber'}*/}
                    {/*    label={'Số máy'}*/}
                    {/*>*/}
                    {/*  <Input disabled={true}/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item
                        name={'vehicleLine'}
                        label={'Dòng xe'}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'seatNumber'}
                        label={'Số chỗ'}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                        rules={[{required: true}]}
                        name={'customerName'}
                        label={'Tên khách hàng'}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                        rules={[{required: true}]}
                        name={'customerPhoneNumber'}
                        label={'SĐT'}
                    >
                      <Input/>
                    </Form.Item>

                    <Form.Item
                        rules={[{required: false}]}
                        name={'contractPrice'}
                        label={(
                            <div className={'priceActions'}>
                              <div className="left">Giá trị</div>
                              <div className="right">
                                {prices.map(price => {
                                  return (
                                      <div onClick={() => {
                                        taskForm.setFieldValue('contractPrice', price * 1000);
                                      }} className="price">{price}</div>
                                  )
                                })}
                              </div>
                            </div>
                        )}
                    >
                      <InputNumber
                          style={{width: '100%'}}
                          formatter={(value) => `VNĐ ${value}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/VNĐ\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    <Form.Item
                        rules={[{required: false}]}
                        name={'contractFrom'}
                        label={'Ngày bắt đầu HĐ'}
                    >
                      <DatePicker
                          format={CONFIG.humanDateFormat}
                          onChange={handleOnChangeContractFrom}
                          style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        readOnly
                        name={'contractTo'}
                        rules={[{required: false}]}
                        label={'Ngày kết thúc HĐ'}
                    >
                      <DatePicker format={CONFIG.humanDateFormat} readOnly style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        rules={[{required: false}]}
                        name={'printNote'}
                        label={'Ghi chú ship (để in)'}
                    >
                      <Input/>
                    </Form.Item>

                    {['manager'].includes(currentRole) && (
                        <Fragment>
                          <Form.Item
                              rules={[{required: false}]}
                              name={'frameNumber'}
                              label={'Số khung'}
                          >
                            <Input/>
                          </Form.Item>
                          <Form.Item
                              rules={[{required: false}]}
                              name={'machineNumber'}
                              label={'Số máy'}
                          >
                            <Input/>
                          </Form.Item>
                          <Form.Item /><Form.Item /><Form.Item />
                        </Fragment>
                    )}

                    <Form.Item
                        style={{width: 'calc(100%/2 - 10px)'}}
                        name={'customerAddress'}
                        onChange={() => {
                          taskForm.setFieldsValue({
                            customerJsonAddress: null,
                          });
                        }}
                        rules={[{required: true}]}
                        label={(
                            <div style={{
                              width: '100%',
                              display: 'flex',
                              justifyItems: 'space-between',
                              alignItems: 'center',
                              gap: '6px'
                            }}>
                              <div className="left">Địa chỉ</div>
                              <div style={{
                                color: customerJsonAddress ? 'green' : 'red'
                              }}>({customerJsonAddress ? 'Địa chỉ đã được chuẩn hóa' : 'Địa chỉ chưa được chuẩn hóa'})</div>
                              <Button className="right"
                                   style={{
                                     cursor: 'pointer',
                                     display: 'flex',
                                     justifyItems: 'center',
                                     alignItems: 'center',
                                     gap: '4px',
                                     marginLeft: '16px',
                                   }}
                                   onClick={() => {
                                     setIsShowAddressModal(true);
                                   }}
                              >
                                <strong>Bấm vào đây để sửa địa chỉ</strong>
                                <CiSearch size={15} />
                              </Button>
                            </div>
                        )}
                    >
                      <Input.TextArea
                          rows={3}/>
                    </Form.Item>

                    <div className="fullWidth" style={{width: 'calc(100%/2 - 10px)'}}>
                      <div className="right">
                        <Form.Item
                            className={'submitRow'}
                            readOnly
                            name={'note'}
                            label={<div style={{minHeight: '32px'}}>Ghi chú chung</div>}
                        >
                          <Input.TextArea rows={3}/>
                        </Form.Item>
                      </div>
                    </div>

                    <Form.Item
                        name="checkedAddress"
                        valuePropName="checked" // Use for boolean fields
                        hidden={true} // This hides the field
                    >
                      <Input type="checkbox" /> {/* You can use Input or Checkbox */}
                    </Form.Item>

                    <Form.Item
                        name="customerJsonAddress"
                        hidden={true}
                    />

                    <Form.Item className={'submitRow'}>
                      <Button block type="primary" htmlType="submit">Cập nhật
                        thông tin</Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="footerAction">
                  <Button disabled={activeIndex === 0} block type="default"
                          onClick={handlePreviousTask}>
                    <div className="flexCenter">
                      <GrLinkPrevious size={25}/>
                      <span>Quay lại đơn trước</span>
                    </div>
                  </Button>
                  {['manager', 'printer'].includes(currentRole) && (
                      <Button onClick={handlePrintSingleTask}>
                        <div className="flexCenter">
                          <span>In đơn lẻ</span>
                          <IoIosPrint />
                        </div>
                      </Button>
                  )}
                  <Button disabled={activeIndex >= (data?.list || data?.detail?.tasks)?.length - 1} block
                          type="default" onClick={handleNextTask}>
                    <div className="flexCenter">
                      <span>Đơn tiếp theo</span>
                      <GrLinkNext size={25}/>
                    </div>
                  </Button>
                </div>
              </Fragment>
          )}
        </div>

        {isShowAddressModal && (
            <AddressModal
                customerAddress={customerAddress}
                customerJsonAddress={customerJsonAddress}
                isShowAddressModal={isShowAddressModal}
                setIsShowAddressModal={setIsShowAddressModal}
                onCloseModal={() => {
                  setIsShowAddressModal(false)
                }}
                onUseAddress={({customerAddress, customerJsonAddress}) => {
                  taskForm.setFieldsValue({
                    checkedAddress: true,
                    customerJsonAddress,
                    customerAddress,
                  })
                }}
            />
        )}
      </Modal>
  );
}
