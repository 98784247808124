import styles from './_page.module.scss';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message, Modal, Popconfirm,
  Space,
} from 'antd';
import {Link, useHistory, useParams} from 'react-router-dom';
import {useEffect, useState, Fragment} from 'react';
import useStrapiRest from '../../hooks/useStrapiRest';
import _pageConfig from './_pageConfig';
import {AuthLayout} from '../../components/layouts/AuthLayout';
import CONFIG from '../../config';
import {AiFillPrinter, AiOutlinePauseCircle} from 'react-icons/ai';
import {GrInProgress} from 'react-icons/gr';
import {FcCallback, FcMissedCall, FcShipped} from 'react-icons/fc';
import {MdOutlineSmsFailed} from 'react-icons/md';
import {BiHappyHeartEyes, BiSkipNext} from 'react-icons/bi';
import {PiSealCheck} from 'react-icons/pi';
import moment from 'moment';
import {exportTasksForPrint, exportTasksForShip, formatDate, genPhoneQRCode} from '../../helper';
import dayjs from 'dayjs';
import {TaskModal} from '../../components/TaskModal';

const endpoint = _pageConfig.endpoint;
export default function ShipHistoryUpdatePage() {
  const [actions, data] = useStrapiRest(endpoint);
  const params = useParams();
  const [id, setId] = useState();
  const history = useHistory();
  const [form] = Form.useForm();
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [qrCodeUrl, setQRCodeUrl] = useState([]);
  const [taskForm] = Form.useForm();
  const [userActions, userData] = useStrapiRest('users');

  useEffect(() => {
    (async () => {
      setId(params.id);
      await actions.getItem(params.id, {
        populate: '*',
      });

      await userActions.getItems({
        populate: ['role'],
        pagination: {
          limit: 1000
        }
      });
    })();
  }, [params.id]);

  useEffect( () => {
    (async () => {
      if (currentTask) {
        const qrCode = await genPhoneQRCode(currentTask.customerPhoneNumber);
        console.log('DEBUG qrCode', qrCode);
        setQRCodeUrl(qrCode);
        taskForm.setFieldsValue({
          ...currentTask,
          contractFrom: currentTask.contractFrom ? dayjs(currentTask.contractFrom) : dayjs(),
          contractTo: currentTask.contractTo ? dayjs(currentTask.contractTo) : dayjs(),
        })
      }
    })()
  }, [currentTask]);

  function handleProcessTask(task) {
    setCurrentTask(task);
    setIsOpenTaskModal(true);
    console.log('DEBUG task', task);
  }

  async function handleChangeTaskStatus(status) {
    try {
      await actions.updateItem(currentTask.id, {
        ...currentTask,
        taskStatus: status
      });
      await actions.getItem(currentTask.id);
      message.success('Thay đổi trạng thái thành công');
    } catch (e) {
      message.error('Lỗi khi thay đổi trạng thái');
    }
  }


  function handleNextTask() {
    setActiveIndex(activeIndex + 1)
    setCurrentTask({
      ...data.detail.tasks[activeIndex + 1]
    })
  }

  function handlePreviousTask() {
    setActiveIndex(activeIndex - 1)
    setCurrentTask({
      ...data.detail.tasks[activeIndex - 1]
    })
  }

  async function onTaskUpdate(values) {
    console.log('DEBUG values', values);
    const clone = {
      ...values
    }
    clone.contractFrom = clone.contractFrom ? moment(clone.contractFrom.toString()).format(CONFIG.apiDateFormat).valueOf() : null;
    clone.contractTo = clone.contractTo ? moment(clone.contractTo.toString()).format(CONFIG.apiDateFormat).valueOf() : null;

    await actions.updateItem(currentTask.id, {
      ...currentTask,
      ...clone
    });
    await actions.getItem(currentTask.id);
    message.success('Cập nhật thông tin thành công');
  }

  async function handleExportAgain() {
    await exportTasksForShip(data.detail.tasks, 'Ship export - ' + moment().format(CONFIG.apiDatetimeFormat).valueOf());
  }

  return (
      <AuthLayout pageTitle={'Xem chi tiết đơn đã ship'}>
        <div className={'pageWrapper'}>
          <div className={'pageContent'}>
            <div className={styles.moduleWrapper}>
              <div className="printTableHeader">
                <div className="left">
                  <strong>Người xử lý in: {data?.detail?.user?.nickName}</strong>
                  <br/>
                  <strong>Thời gian ship: {formatDate(data?.detail?.createdAt)}</strong>
                </div>
                <div className="right">
                  <Button type={'primary'} onClick={handleExportAgain}>Xuất lại file Exel</Button>
                </div>
              </div>
              <table className={'tableContent'}>
                <thead>
                <tr>
                  <th className={'no'}>STT</th>
                  <th>Biển số</th>
                  {/*<th>Số khung</th>*/}
                  {/*<th>Số máy</th>*/}
                  {/*<th>Dòng xe</th>*/}
                  <th>Họ tên</th>
                  {/*<th>Địa chỉ</th>*/}
                  <th>SĐT</th>
                  <th>Hạn BH</th>
                  {/*<th>Số chỗ</th>*/}
                  <th>Số HĐ</th>
                  <th>Ghi chú</th>
                  <th>Trạng thái</th>
                  <th className={'actions'} style={{width: '85px !important'}}>Thao tác</th>
                </tr>
                </thead>
                <tbody>
                {data?.detail?.tasks?.map((item, index) => {
                  return (
                      <tr key={item.id}>
                        <td className={'no'}>
                          {index + 1}
                        </td>
                        <td>
                          {item.plateNumber}
                        </td>
                        {/*<td>*/}
                        {/*  {item.frameNumber}*/}
                        {/*</td>*/}
                        {/*<td>*/}
                        {/*  {item.machineNumber}*/}
                        {/*</td>*/}
                        {/*<td>*/}
                        {/*  {item.vehicleLine}*/}
                        {/*</td>*/}
                        <td>
                          {item.customerName}
                        </td>
                        {/*<td style={{maxWidth: '150px'}} className={'oneLine'}>*/}
                        {/*  {item.customerAddress}*/}
                        {/*</td>*/}
                        <td>
                          {item.customerPhoneNumber}
                        </td>
                        <td>
                          {item.contractTo}
                        </td>
                        {/*<td>*/}
                        {/*  {item.seatNumber}*/}
                        {/*</td>*/}
                        <td>
                          {item.contractNo}
                        </td>
                        <td>{item.note}</td>
                        <td>
                          {CONFIG.taskStatuses.map(status => {
                            if (status.value === item.taskStatus) {
                              return status.label
                            }
                          })}
                        </td>
                        <td className={'actions'} style={{width: '85px !important'}}>
                          <Space>
                            <Button type={'primary'}
                                    onClick={() => {
                                      setActiveIndex(index)
                                      handleProcessTask(item)
                                    }}
                            >Xem</Button>
                          </Space>
                        </td>
                      </tr>
                  )
                })}
                </tbody>
              </table>

              <TaskModal
                  isOpenTaskModal={isOpenTaskModal}
                  onCancel={() => {
                    setIsOpenTaskModal(false);
                  }}
                  data={data}
                  currentTask={currentTask}
                  onTaskUpdate={onTaskUpdate}
                  userData={userData}
                  handleChangeTaskStatus={handleChangeTaskStatus}
                  activeIndex={activeIndex}
                  handlePreviousTask={handlePreviousTask}
                  handleNextTask={handleNextTask}
              />

              {/*<Modal*/}
              {/*    centered*/}
              {/*    width={'800px'}*/}
              {/*    title={null}*/}
              {/*    closeIcon={null}*/}
              {/*    open={isOpenTaskModal}*/}
              {/*    onCancel={() => {*/}
              {/*      setIsOpenTaskModal(false)*/}
              {/*    }}*/}
              {/*    footer={null}*/}
              {/*    className={styles.moduleWrapper}*/}
              {/*>*/}
              {/*  <div className={`taskContent`}>*/}
              {/*    {!!currentTask && (*/}
              {/*        <Fragment>*/}
              {/*          <div className="taskUpdateForm">*/}
              {/*            <Form*/}
              {/*                form={taskForm}*/}
              {/*                onFinish={onTaskUpdate}*/}
              {/*                colon={false}*/}
              {/*                layout="vertical"*/}
              {/*                style={{*/}
              {/*                  width: '100%',*/}
              {/*                }}*/}
              {/*                className={'threeColumns'}*/}
              {/*            >*/}
              {/*              <Form.Item*/}
              {/*                  name={'plateNumber'}*/}
              {/*                  label={<strong>Biển số</strong>}*/}
              {/*              >*/}
              {/*                <Input/>*/}
              {/*              </Form.Item>*/}
              {/*              <Form.Item*/}
              {/*                  name={'frameNumber'}*/}
              {/*                  label={'Số khung'}*/}
              {/*              >*/}
              {/*                <Input disabled={true} />*/}
              {/*              </Form.Item>*/}
              {/*              <Form.Item*/}
              {/*                  name={'machineNumber'}*/}
              {/*                  label={'Số máy'}*/}
              {/*              >*/}
              {/*                <Input disabled={true}/>*/}
              {/*              </Form.Item>*/}
              {/*              <Form.Item*/}
              {/*                  name={'vehicleLine'}*/}
              {/*                  label={'Dòng xe'}*/}
              {/*              >*/}
              {/*                <Input/>*/}
              {/*              </Form.Item>*/}
              {/*              <Form.Item*/}
              {/*                  name={'customerName'}*/}
              {/*                  label={<strong>Tên khách hàng</strong>}*/}
              {/*              >*/}
              {/*                <Input/>*/}
              {/*              </Form.Item>*/}
              {/*              <Form.Item*/}
              {/*                  name={'customerPhoneNumber'}*/}
              {/*                  label={'SĐT'}*/}
              {/*              >*/}
              {/*                <Input/>*/}
              {/*              </Form.Item>*/}
              {/*              <Form.Item*/}
              {/*                  name={'customerAddress'}*/}
              {/*                  label={<strong>Địa chỉ</strong>}*/}
              {/*              >*/}
              {/*                <Input.TextArea/>*/}
              {/*              </Form.Item>*/}
              {/*              <Form.Item*/}
              {/*                  name={'contractNo'}*/}
              {/*                  label={'Mã hợp đồng'}*/}
              {/*              >*/}
              {/*                <Input/>*/}
              {/*              </Form.Item>*/}

              {/*              <Form.Item*/}
              {/*                  name={'contractPrice'}*/}
              {/*                  label={<strong>Giá trị HĐ</strong>}*/}
              {/*              >*/}
              {/*                <InputNumber*/}
              {/*                    style={{width: '100%'}}*/}
              {/*                    formatter={(value) => `VNĐ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
              {/*                    parser={(value) => value.replace(/VNĐ\s?|(,*)/g, '')}*/}
              {/*                />*/}
              {/*              </Form.Item>*/}

              {/*              <Form.Item*/}
              {/*                  name={'contractFrom'}*/}
              {/*                  label={<strong>Ngày bắt đầu HĐ</strong>}*/}
              {/*              >*/}
              {/*                <DatePicker style={{width: '100%'}}/>*/}
              {/*              </Form.Item>*/}

              {/*              <Form.Item*/}
              {/*                  readOnly*/}
              {/*                  name={'contractTo'}*/}
              {/*                  label={'Ngày kết thúc HĐ'}*/}
              {/*              >*/}
              {/*                <DatePicker readOnly style={{width: '100%'}}/>*/}
              {/*              </Form.Item>*/}

              {/*              <Form.Item*/}
              {/*                  className={'submitRow'}*/}
              {/*                  readOnly*/}
              {/*                  name={'note'}*/}
              {/*                  label={'Ghi chú'}*/}
              {/*              >*/}
              {/*                <Input.TextArea/>*/}
              {/*              </Form.Item>*/}

              {/*              <Form.Item className={'submitRow'}>*/}
              {/*                <Button block type="primary" htmlType="submit">Cập nhật thông tin</Button>*/}
              {/*              </Form.Item>*/}
              {/*            </Form>*/}
              {/*          </div>*/}
              {/*          <div className="actionAndImage">*/}
              {/*            <div className="left">*/}
              {/*              <img style={{width: '200px', border: '1px solid black'}} src={qrCodeUrl} alt="Img"/>*/}
              {/*            </div>*/}
              {/*            <div className="right">*/}
              {/*              <div className="taskActions">*/}
              {/*                /!*<Button*!/*/}
              {/*                /!*    onClick={() => {*!/*/}
              {/*                /!*      handleChangeTaskStatus('NEW')*!/*/}
              {/*                /!*    }}*!/*/}
              {/*                /!*    disabled={currentTask.taskStatus === 'NEW'} block type={'default'}>*!/*/}
              {/*                /!*  <AiOutlinePauseCircle size={25} />*!/*/}
              {/*                /!*  Chưa xử lý</Button>*!/*/}
              {/*                <Button*/}
              {/*                    onClick={() => {*/}
              {/*                      handleChangeTaskStatus('IN_PROGRESS')*/}
              {/*                    }}*/}
              {/*                    disabled={currentTask.taskStatus === 'IN_PROGRESS'} block type={'default'}>*/}
              {/*                  <GrInProgress size={25} />*/}
              {/*                  Đang xử lý</Button>*/}
              {/*                <Button*/}
              {/*                    onClick={() => {*/}
              {/*                      handleChangeTaskStatus('NO_PICKUP')*/}
              {/*                    }}*/}
              {/*                    disabled={currentTask.taskStatus === 'NO_PICKUP'} block type={'default'}>*/}
              {/*                  <FcMissedCall color={'red'} size={25} />*/}
              {/*                  Không liên hệ được</Button>*/}
              {/*                <Button*/}
              {/*                    onClick={() => {*/}
              {/*                      handleChangeTaskStatus('CALL_LATER')*/}
              {/*                    }}*/}
              {/*                    disabled={currentTask.taskStatus === 'CALL_LATER'} block type={'default'}>*/}
              {/*                  <FcCallback color={'red'} size={25} />*/}
              {/*                  Gọi lại sau</Button>*/}
              {/*                <Button*/}
              {/*                    onClick={() => {*/}
              {/*                      handleChangeTaskStatus('SALE_FAIL')*/}
              {/*                    }}*/}
              {/*                    disabled={currentTask.taskStatus === 'SALE_FAIL'} block type={'default'}>*/}
              {/*                  <MdOutlineSmsFailed color={'red'} size={25} />*/}
              {/*                  Khách từ chối mua</Button>*/}
              {/*                <Popconfirm*/}
              {/*                    okText={'Thông tin chính xác rồi'}*/}
              {/*                    cancelText={'Kiểm tra lại'}*/}
              {/*                    title="Vui lòng xác nhận!"*/}
              {/*                    description="Các thông tin để làm hợp đồng đã chính xác chưa?"*/}
              {/*                    onConfirm={async () => {*/}
              {/*                      handleChangeTaskStatus('SALE_SUCCESS')*/}
              {/*                    }}*/}
              {/*                >*/}
              {/*                  <Button disabled={currentTask.taskStatus === 'SALE_SUCCESS'} block type={'default'}>*/}
              {/*                    <BiHappyHeartEyes color={'green'} size={25} />*/}
              {/*                    Chốt đơn thành công</Button>*/}
              {/*                </Popconfirm>*/}
              {/*                <Button*/}
              {/*                    onClick={() => {*/}
              {/*                      handleChangeTaskStatus('MANAGER_CONFIRMED')*/}
              {/*                    }}*/}
              {/*                    disabled={currentTask.taskStatus === 'MANAGER_CONFIRMED'} block type={'default'}>*/}
              {/*                  <PiSealCheck color={'green'} size={25} />*/}
              {/*                  Đã phê duyệt bởi quản lý</Button>*/}
              {/*                <Button*/}
              {/*                    onClick={() => {*/}
              {/*                      handleChangeTaskStatus('PRINTED')*/}
              {/*                    }}*/}
              {/*                    disabled={currentTask.taskStatus === 'PRINTED'} block type={'default'}>*/}
              {/*                  <AiFillPrinter color={'green'} size={25} />*/}
              {/*                  Đã in vận đơn</Button>*/}
              {/*                <Button*/}
              {/*                    onClick={() => {*/}
              {/*                      handleChangeTaskStatus('SHIPPED')*/}
              {/*                    }}*/}
              {/*                    disabled={currentTask.taskStatus === 'SHIPPED'} block type={'default'}>*/}
              {/*                  <FcShipped size={25} />*/}
              {/*                  Đã shipped đơn hàng</Button>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*          <div className="footerAction">*/}
              {/*            <Button disabled={activeIndex === 0} block type="default" onClick={handlePreviousTask}>*/}
              {/*              <div className="flexCenter">*/}
              {/*                <BiSkipNext size={25} />*/}
              {/*                <span>Quay lại đơn trước</span>*/}
              {/*              </div>*/}
              {/*            </Button>*/}
              {/*            <Button disabled={activeIndex === data.detail.tasks.length - 1} block type="default" onClick={handleNextTask}>*/}
              {/*              <div className="flexCenter">*/}
              {/*                <BiSkipNext size={25} />*/}
              {/*                <span>Đơn tiếp theo</span>*/}
              {/*              </div>*/}
              {/*            </Button>*/}
              {/*          </div>*/}
              {/*        </Fragment>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</Modal>*/}
            </div>
          </div>
        </div>
      </AuthLayout>
  );
}
